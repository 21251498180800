"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateArcDto = void 0;
const graphql_1 = require("@nestjs/graphql");
const baseUpdate_dto_1 = require("../base/baseUpdate.dto");
let UpdateArcDto = class UpdateArcDto extends baseUpdate_dto_1.BaseUpdateDto {
};
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "bookId", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "arcTeamId", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UpdateArcDto.prototype, "specialInformation", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "arcType", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "discoverableType", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "participationType", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "participationPreventionType", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "copyCountType", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "copyCount", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "seriesLimitType", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "reviewFlags", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "optionalReviewFlags", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "requiredReviewCount", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], UpdateArcDto.prototype, "liveDate", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "reviewDays", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], UpdateArcDto.prototype, "stopDownloadDate", void 0);
__decorate([
    (0, graphql_1.Field)(type => Boolean, { nullable: true }),
    __metadata("design:type", Boolean)
], UpdateArcDto.prototype, "neverEndingPrivate", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UpdateArcDto.prototype, "usDownloadCodes", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UpdateArcDto.prototype, "ukDownloadCodes", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UpdateArcDto.prototype, "authorsDirectDownloadCodes", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UpdateArcDto.prototype, "koboDownloadCodes", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "vipExclusiveAccessDays", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], UpdateArcDto.prototype, "dueDate", void 0);
__decorate([
    (0, graphql_1.Field)(type => Boolean, { nullable: true }),
    __metadata("design:type", Boolean)
], UpdateArcDto.prototype, "reviewLinksCompleted", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateArcDto.prototype, "alertFlags", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UpdateArcDto.prototype, "liveDateStr", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UpdateArcDto.prototype, "stopDownloadDateStr", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UpdateArcDto.prototype, "dueDateStr", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UpdateArcDto.prototype, "dueCutOffDateStr", void 0);
UpdateArcDto = __decorate([
    (0, graphql_1.ArgsType)()
], UpdateArcDto);
exports.UpdateArcDto = UpdateArcDto;
