"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArcTeamDto = void 0;
const graphql_1 = require("@nestjs/graphql");
const baseModel_dto_1 = require("../base/baseModel.dto");
const model_dto_1 = require("./autoAction/model.dto");
const model_dto_2 = require("./member/model.dto");
const arc_1 = require("../arc");
let ArcTeamDto = class ArcTeamDto extends baseModel_dto_1.BaseModelDto {
};
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcTeamDto.prototype, "name", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcTeamDto.prototype, "image", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcTeamDto.prototype, "description", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcTeamDto.prototype, "defaultDisclaimer", void 0);
__decorate([
    (0, graphql_1.Field)(t => [model_dto_1.ArcTeamAutoActionDto], { nullable: true }),
    __metadata("design:type", Array)
], ArcTeamDto.prototype, "autoActionEmailAddresses", void 0);
__decorate([
    (0, graphql_1.Field)(t => [model_dto_2.ArcTeamMemberDto], { nullable: true }),
    __metadata("design:type", Array)
], ArcTeamDto.prototype, "arcTeamMembers", void 0);
__decorate([
    (0, graphql_1.Field)(t => [arc_1.ArcDto], { nullable: true }),
    __metadata("design:type", Array)
], ArcTeamDto.prototype, "arcs", void 0);
__decorate([
    (0, graphql_1.Field)(t => model_dto_2.ArcTeamMemberDto, { nullable: true }),
    __metadata("design:type", model_dto_2.ArcTeamMemberDto)
], ArcTeamDto.prototype, "clientUserTeamMember", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Boolean)
], ArcTeamDto.prototype, "isDraft", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcTeamDto.prototype, "applicantCount", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcTeamDto.prototype, "followerCount", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], ArcTeamDto.prototype, "isActivePublisherTeam", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], ArcTeamDto.prototype, "userIsFollowing", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcTeamDto.prototype, "reviewCount", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Float, { nullable: true }),
    __metadata("design:type", Number)
], ArcTeamDto.prototype, "averageRating", void 0);
ArcTeamDto = __decorate([
    (0, graphql_1.ObjectType)()
], ArcTeamDto);
exports.ArcTeamDto = ArcTeamDto;
