"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var UserDto_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserDto = void 0;
const graphql_1 = require("@nestjs/graphql");
const userEmail_dto_1 = require("./userEmail.dto");
const baseModelWithoutUser_dto_1 = require("../base/baseModelWithoutUser.dto");
const preferences_1 = require("./preferences");
const claim_1 = require("../arc/claim");
const basicModel_dto_1 = require("./basicModel.dto");
let UserDto = UserDto_1 = class UserDto extends baseModelWithoutUser_dto_1.BaseModelWithoutUserDto {
};
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "emailAddress", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], UserDto.prototype, "termsConsentDate", void 0);
__decorate([
    (0, graphql_1.Field)(t => preferences_1.UserPreferencesDto, { nullable: true }),
    __metadata("design:type", preferences_1.UserPreferencesDto)
], UserDto.prototype, "preferences", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UserDto.prototype, "consentMethod", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], UserDto.prototype, "termsConsentRevokedDate", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "reviewerRole", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "authorRole", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UserDto.prototype, "selectedPrimaryRole", void 0);
__decorate([
    (0, graphql_1.Field)(t => String, { nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "name", void 0);
__decorate([
    (0, graphql_1.Field)(t => String, { nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "image", void 0);
__decorate([
    (0, graphql_1.Field)(t => String, { nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "kindleEmail", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], UserDto.prototype, "verifiedKindleEmail", void 0);
__decorate([
    (0, graphql_1.Field)(t => String, { nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "amazonProfileLink", void 0);
__decorate([
    (0, graphql_1.Field)(t => [userEmail_dto_1.UserEmailDto], { nullable: true }),
    __metadata("design:type", Array)
], UserDto.prototype, "userEmails", void 0);
__decorate([
    (0, graphql_1.Field)(t => [UserDto_1], { nullable: true }),
    __metadata("design:type", Array)
], UserDto.prototype, "following", void 0);
__decorate([
    (0, graphql_1.Field)(t => [basicModel_dto_1.BasicUserDto], { nullable: true }),
    __metadata("design:type", Array)
], UserDto.prototype, "canActAs", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "hasActingAs", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UserDto.prototype, "status", void 0);
__decorate([
    (0, graphql_1.Field)(t => [claim_1.ArcClaimDto], { nullable: true }),
    __metadata("design:type", Array)
], UserDto.prototype, "arcClaims", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], UserDto.prototype, "topReviewerAppStatusDate", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UserDto.prototype, "topReviewerAppStatus", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "topReviewerAppStatusReason", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], UserDto.prototype, "isAdmin", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UserDto.prototype, "pirateReportCount", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "unsubId", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "stripeCustomerId", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], UserDto.prototype, "isLocked", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "completedTours", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UserDto.prototype, "cancelReason", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "cancelReasonDescription", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], UserDto.prototype, "forceLoyaltyDiscountView", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "countryCode", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date
    // comma separated list
    )
], UserDto.prototype, "lastLoginDate", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UserDto.prototype, "usedCouponCodes", void 0);
UserDto = UserDto_1 = __decorate([
    (0, graphql_1.ObjectType)()
], UserDto);
exports.UserDto = UserDto;
