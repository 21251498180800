"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceDto = void 0;
const graphql_1 = require("@nestjs/graphql");
let InvoiceDto = class InvoiceDto {
};
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], InvoiceDto.prototype, "id", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], InvoiceDto.prototype, "description", void 0);
__decorate([
    (0, graphql_1.Field)(t => Date),
    __metadata("design:type", Date)
], InvoiceDto.prototype, "paidOn", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], InvoiceDto.prototype, "cardLast4", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], InvoiceDto.prototype, "cardType", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], InvoiceDto.prototype, "downloadLink", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], InvoiceDto.prototype, "status", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], InvoiceDto.prototype, "paymentFailedReason", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], InvoiceDto.prototype, "paymentFailedDescription", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], InvoiceDto.prototype, "paymentFailedType", void 0);
InvoiceDto = __decorate([
    (0, graphql_1.ObjectType)()
], InvoiceDto);
exports.InvoiceDto = InvoiceDto;
