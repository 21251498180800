import VueI18n from "vue-i18n"

const merge = (target: any, source: any) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object && key in target) Object.assign(source[key], merge(target[key], source[key]))
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source)
  return target
}

function mergeKeys (messages: VueI18n.LocaleMessages, locales: any) {
  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      if (messages[locale] === void 0) {
        // locale doesn't exist on our messages i.e en-US. Add it.
        messages[locale] = locales(key)
      } else {
        // locale exists. Merge with the new one (this will deep merge so we can override one property).
        merge(messages[locale], locales(key))
      }
    }
  })
}

function loadLocaleMessages () {
  const messages: VueI18n.LocaleMessages = {}
  // Translations shared by the API and apps
  // @ts-ignore
  mergeKeys(messages, require.context('../../../../../common/src/i18n/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i))

  // Base translations which are used for each app (system ones mainly)
  // @ts-ignore
  mergeKeys(messages, require.context('../../../common-app/src/i18n/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i))

  // Specific app translations
  // @ts-ignore
  mergeKeys(messages, require.context('src/i18n/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i))

  return messages
}

const i18nConfig: VueI18n.I18nOptions = {
  locale: 'en-US',
  fallbackLocale: 'en-US',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true
}

const configure = (i18n: any, app: any, Vue: any) => {
  /**
   * Helper function for i18n. By default, if a key isn't found, the key is returned.
   * Sometimes though, we just want an empty string (or something else) as a default,
   * this function provides a way for that to happen.
   * @param key
   * @param defaultString
   */
  Vue.prototype.$tcD = (key: string, defaultString: string) => {
    const result = i18n.tc(key)
    if (key === result) {
      return ''
    }
    return result
  }
  // Set i18n instance on app
  // @ts-ignore
  app.i18n = i18n
}

export { i18nConfig, configure }
