"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AuthorRole_1 = require("./AuthorRole");
class FreeAuthor extends AuthorRole_1.AuthorRole {
    constructor() {
        super(...arguments);
        // create: string[] = [...baseAuthorCRUDModels.splice(baseAuthorCRUDModels.indexOf('message'), 1)]
        this.create = ['pen-name', 'book', 'arc-team', 'arc', 'review', 'category'];
    }
}
exports.default = FreeAuthor;
