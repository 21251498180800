import { LOCALHOST, PORT } from 'booksprout-app'

export default {
  axios: {
    url: `http://${LOCALHOST}:${PORT}/`
  },
  apolloClient: {
    url: `http://${LOCALHOST}:${PORT}/graphql`
  }
}
