"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PenNameDto = void 0;
const graphql_1 = require("@nestjs/graphql");
const follower_model_dto_1 = require("./follower.model.dto");
const baseModel_dto_1 = require("../base/baseModel.dto");
const graphql_2 = require("@nestjs/graphql");
let PenNameDto = class PenNameDto extends baseModel_dto_1.BaseModelDto {
};
__decorate([
    (0, graphql_1.Field)(type => String),
    __metadata("design:type", String)
], PenNameDto.prototype, "name", void 0);
__decorate([
    (0, graphql_1.Field)(type => String),
    __metadata("design:type", String)
], PenNameDto.prototype, "firstName", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], PenNameDto.prototype, "lastName", void 0);
__decorate([
    (0, graphql_1.Field)(t => [follower_model_dto_1.PenNameFollowerDto], { nullable: true }),
    __metadata("design:type", Array)
], PenNameDto.prototype, "followers", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], PenNameDto.prototype, "updatedDate", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], PenNameDto.prototype, "bio", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], PenNameDto.prototype, "image", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], PenNameDto.prototype, "facebookLink", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], PenNameDto.prototype, "bookbubLink", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], PenNameDto.prototype, "twitterLink", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], PenNameDto.prototype, "instagramLink", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], PenNameDto.prototype, "websiteLink", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], PenNameDto.prototype, "newsletterLink", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], PenNameDto.prototype, "tiktokLink", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], PenNameDto.prototype, "amazonLink", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], PenNameDto.prototype, "goodreadsLink", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], PenNameDto.prototype, "followerCount", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Float, { nullable: true }),
    __metadata("design:type", Number)
], PenNameDto.prototype, "averageRating", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], PenNameDto.prototype, "reviewCount", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Boolean)
], PenNameDto.prototype, "isDraft", void 0);
PenNameDto = __decorate([
    (0, graphql_2.ObjectType)()
], PenNameDto);
exports.PenNameDto = PenNameDto;
