"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArcTeamSeeder = void 0;
const seeder_1 = require("../seeder");
const module_constants_1 = require("../../module.constants");
const user_1 = require("../user");
const userSeeder = new user_1.UserSeeder();
const seedUsers = Object.assign(Object.assign({}, userSeeder.getLiveDataObject()), userSeeder.getTestDataObject());
class ArcTeamSeeder extends seeder_1.Seeder {
    constructor() {
        super(...arguments);
        this.type = 'arcTeam';
        this.maxModelCount = 40;
    }
    getLiveDataObject() {
        return {
            DeletedTeam: {
                id: 1,
                name: '[Deleted Team]',
                image: '',
                description: 'Deleted Team Placeholder',
                userId: seedUsers.DeletedUser.id
            }
        };
    }
    getTestDataObject() {
        if (process.env.BUILD_MODE === 'master') {
            return {};
        }
        const seedUsers = new user_1.UserSeeder().getTestDataObject();
        const testData = {
            TimothyZahnFans: {
                id: this.getNextId(),
                name: 'Timothy Zahn Fans',
                image: 'TimothyZahn.jpg',
                description: 'A team for all those wanting to get ARCs for Timothy Zahn\'s latest and greatest!',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.Me.id,
                members: [
                    {
                        userId: seedUsers.StanRizzo.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.HanSolo.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.REJECTED,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.LukeSkywalker.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.BLOCKED,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.MaraJade.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.AWAITING,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.Thrawn.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.VIP
                    },
                    {
                        userId: seedUsers.ApproveUser.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.AWAITING,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.RejectUser.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.AWAITING,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    }
                ]
            },
            DrewKarpyshynFans: {
                id: this.getNextId(),
                name: 'Drew Karpyshyn Fans',
                image: 'DrewKarpyshyn.jpg',
                description: 'A team for all those wanting to get ARCs for Drew Karpyshyn\'s mostly latest and mostly greatest!',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.Publisher.id,
                members: [
                    {
                        userId: seedUsers.Publisher.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.HanSolo.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.LukeSkywalker.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.AWAITING,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    }
                ]
            },
            TeamForTheDarkSide: {
                id: this.getNextId(),
                name: 'Join the Darkside',
                image: 'DarkSide.jpg',
                description: 'Note: 2 people max! The rule of 2 still applies! Books for all dark side fans!',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.Me.id,
                members: [
                    {
                        userId: seedUsers.Thrawn.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.DarthVadar.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.StanRizzo.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    }
                ]
            },
            TeamForTheLightSide: {
                id: this.getNextId(),
                name: 'Become one with the force',
                image: 'LightSide.jpg',
                description: 'Books for all lightside fans!',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.Me.id,
                members: [
                    {
                        userId: seedUsers.MaraJade.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.LukeSkywalker.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.VIP
                    },
                    {
                        userId: seedUsers.HanSolo.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.Publisher.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    }
                ]
            },
            NoMemberTeam: {
                id: this.getNextId(),
                name: 'Team No Mates',
                image: 'test.png',
                description: 'No users, no image - sorry little thing ...',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.Me.id
            },
            FreeAuthorTeam: {
                id: this.getNextId(),
                name: 'Free Author Team',
                image: 'test.png',
                description: 'Free author team description',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.FreeAuthor.id
            },
            GrowingAuthorTeam: {
                id: this.getNextId(),
                name: 'Growing Author Team',
                image: 'test.png',
                description: 'Growing author team description',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.GrowingAuthor.id
            },
            ProAuthorTeam: {
                id: this.getNextId(),
                name: 'Pro Author Team',
                image: 'test.png',
                description: 'Pro author team description',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.ProAuthor.id
            },
            BestSellerAuthorTeam: {
                id: this.getNextId(),
                name: 'BestSeller Author Team',
                image: 'test.png',
                description: 'BestSeller author team description',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.BestsellingAuthor.id
            },
            PublisherTeam: {
                id: this.getNextId(),
                name: 'Publisher Author Team',
                image: 'test.png',
                description: 'Publisher author team description',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.Publisher.id
            },
            ReviewDueSoonTeam: {
                id: this.getNextId(),
                name: 'Review Due Soon Team',
                image: 'test.png',
                description: 'Review due soon team!',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.Publisher.id,
                members: [
                    {
                        userId: seedUsers.HasReviewDueSoon.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    }
                ]
            },
            PlaceholderTeamOne: {
                id: this.getNextId(),
                name: 'Placeholder Team One',
                image: 'test.png',
                description: 'Team which can be used for testing applicants',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.Me.id,
                members: [
                    {
                        userId: seedUsers.StanRizzo.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.AWAITING,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    }
                ]
            },
            PlaceholderTeamTwo: {
                id: this.getNextId(),
                name: 'Placeholder Team Two',
                image: 'test.png',
                description: 'Team which can be used for testing applicants',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.Me.id,
                members: [
                    {
                        userId: seedUsers.StanRizzo.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.AWAITING,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    }
                ]
            },
            StanBlocked: {
                id: this.getNextId(),
                name: 'Stan Blocked',
                image: 'test.png',
                description: 'Stan is blocked from this team',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.Me.id,
                members: [
                    {
                        userId: seedUsers.StanRizzo.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.BLOCKED,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR,
                        statusReason: 'Something',
                        statusReasonType: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS_REASON_TYPE.HARASSMENT
                    },
                    {
                        userId: seedUsers.YourArcs.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.BadBoy.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.BLOCKED,
                        statusReason: '',
                        statusReasonType: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS_REASON_TYPE.GENERIC,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR,
                        report: true
                    }
                ]
            },
            StanRegular: {
                id: this.getNextId(),
                name: 'Stan Regular',
                image: 'test.png',
                description: 'Stan is a Regular member of this team',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.Me.id,
                members: [
                    {
                        userId: seedUsers.StanRizzo.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.YourArcs.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.ClaimOutstandingTeamReviewNotVIP.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    },
                    {
                        userId: seedUsers.ClaimBlockedUser.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.BLOCKED,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    }
                ]
            },
            TeamNotify: {
                id: this.getNextId(),
                name: 'Notifications Test Team',
                image: 'test.png',
                description: 'A team for testing all the notifications',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.NotificationAuthor.id,
                members: [
                    {
                        userId: seedUsers.NotificationReviewer.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.INVITED,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.NONE
                    }
                ]
            },
            OutstandingReviewTeam: {
                id: this.getNextId(),
                name: 'Outstanding Review Team',
                image: 'test.png',
                description: 'A team for testing outstanding review claims',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.Me.id
            },
            AutoAddAutoBlockTeam: {
                id: this.getNextId(),
                name: 'Auto add/block members team',
                image: 'test.png',
                description: 'A team for testing auto adding/blocking members',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.Me.id,
                // using hardcoded email address which is used to register a user and test auto approval
                autoApproveEmailAddresses: [seedUsers.AutoApproveOnTeam.emailAddress, 'auto.approve.on.team.new.user@allangaunt.dev'],
                autoBlockEmailAddresses: [seedUsers.AutoBlockOnTeam.emailAddress]
            },
            TeamForRandomArcs: {
                id: this.getNextId(),
                name: 'Team for random arcs',
                image: 'test.png',
                description: 'A team for random arcs',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.UserForRandomArcs.id,
                members: [{
                        userId: seedUsers.TopReviewerApplicationRejected.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    }]
            },
            EbookAndAudioBook: {
                id: this.getNextId(),
                name: 'EBook and Audio book Team',
                image: 'test.png',
                description: 'Team for books running audio and ebook at same time',
                defaultDisclaimer: module_constants_1.bsConstants.ARC_TEAMS.DISCLAIMERS.FREE_VIA_BS,
                userId: seedUsers.EbookAndAudioBook.id,
                members: [{
                        userId: seedUsers.EbookAndAudioBookReviewer.id,
                        status: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS.ACTIVE,
                        type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.TYPE.REGULAR
                    }]
            }
        };
        return testData;
    }
}
exports.ArcTeamSeeder = ArcTeamSeeder;
