"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageThreadDto = void 0;
const base_1 = require("../../base");
const graphql_1 = require("@nestjs/graphql");
const arc_1 = require("../../arc");
const reviewerThreadUser_dto_1 = require("./reviewerThreadUser.dto");
const authorThreadUser_dto_1 = require("./authorThreadUser.dto");
let MessageThreadDto = class MessageThreadDto extends base_1.BaseModelDto {
};
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], MessageThreadDto.prototype, "state", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Date)
], MessageThreadDto.prototype, "stateDate", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], MessageThreadDto.prototype, "arcId", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], MessageThreadDto.prototype, "reviewerUserId", void 0);
__decorate([
    (0, graphql_1.Field)(t => arc_1.ArcDto),
    __metadata("design:type", arc_1.ArcDto)
], MessageThreadDto.prototype, "arc", void 0);
__decorate([
    (0, graphql_1.Field)(t => authorThreadUser_dto_1.AuthorThreadUserDto),
    __metadata("design:type", authorThreadUser_dto_1.AuthorThreadUserDto)
], MessageThreadDto.prototype, "authorUser", void 0);
__decorate([
    (0, graphql_1.Field)(t => reviewerThreadUser_dto_1.ReviewerThreadUserDto),
    __metadata("design:type", reviewerThreadUser_dto_1.ReviewerThreadUserDto)
], MessageThreadDto.prototype, "reviewerUser", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], MessageThreadDto.prototype, "messageCount", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], MessageThreadDto.prototype, "unreadMessageCount", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Date)
], MessageThreadDto.prototype, "lastMessageDate", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], MessageThreadDto.prototype, "lastMessageText", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Boolean)
], MessageThreadDto.prototype, "blocked", void 0);
MessageThreadDto = __decorate([
    (0, graphql_1.ObjectType)()
], MessageThreadDto);
exports.MessageThreadDto = MessageThreadDto;
