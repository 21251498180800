"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArcSeeder = void 0;
const seeder_1 = require("../seeder");
const module_constants_1 = require("../../module.constants");
const book_1 = require("../book");
const arc_team_1 = require("../arc-team");
const utils_1 = require("../../utils");
const user_1 = require("../user");
const FLAGS = __rest(module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS, []);
const userSeeder = new user_1.UserSeeder();
const seedUsers = Object.assign(Object.assign({}, userSeeder.getLiveDataObject()), userSeeder.getTestDataObject());
const bookSeeder = new book_1.BookSeeder();
const seedBooks = Object.assign(Object.assign({}, bookSeeder.getLiveDataObject()), bookSeeder.getTestDataObject());
const arcTeamSeeder = new arc_team_1.ArcTeamSeeder();
const seedArcTeams = Object.assign(Object.assign({}, arcTeamSeeder.getLiveDataObject()), arcTeamSeeder.getTestDataObject());
class ArcSeeder extends seeder_1.Seeder {
    constructor() {
        super(...arguments);
        this.type = 'arc';
        this.maxModelCount = 130;
        this.processedFakeArcs = 0;
        this.base = {
            discoverableType: module_constants_1.bsConstants.ARCS.DISCOVERABLE_TYPE.PUBLIC,
            participationType: module_constants_1.bsConstants.ARCS.PARTICIPATION_TYPE.PUBLIC,
            participationPreventionType: module_constants_1.bsConstants.ARCS.PARTICIPATION_PREVENTION_TYPE.OUTSTANDING,
            copyCountType: module_constants_1.bsConstants.ARCS.COPY_COUNT_TYPE.ALL,
            seriesLimitType: module_constants_1.bsConstants.ARCS.SERIES_LIMIT_TYPE.ANYONE,
            neverEndingPrivate: false,
            reviewFlags: FLAGS.AMAZON | FLAGS.GOOD_READS,
            requiredReviewCount: 2,
            stopDownloadDate: (0, utils_1.dateAddDays)(30),
            dueDate: (0, utils_1.dateAddDays)(30),
            liveDate: (0, utils_1.dateAddDays)(14),
            reviewDays: 10,
            arcType: module_constants_1.bsConstants.ARCS.TYPE.EBOOK
        };
        this.baseReviewDto = {
            claimId: -1,
            userId: -1,
            disclaimer: 'Disclaimer ...',
            title: 'Title'
        };
        this.baseSiteDto = {
            userId: -1,
            reviewId: -1,
            link: 'www.google.com'
        };
    }
    getLiveDataObject() {
        return {
            DeletedArc: Object.assign(Object.assign({}, this.base), { id: 1, specialInformation: '[Deleted ARC]', userId: seedUsers.DeletedUser.id, bookId: seedBooks.DeletedBook.id, arcTeamId: seedArcTeams.DeletedTeam.id })
        };
    }
    getTestDataObject() {
        if (process.env.BUILD_MODE === 'master') {
            return {};
        }
        const testData = {
            // Alliance is the first ARC as it means stan.rizzo will have a complete review and automatically
            // be upgraded to a "Value Reviewer" gamification level. Not doing this would cause all other claims
            // to trigger a "tooManyArcsDue" error as newReviewer can only have one due ARC.
            Annihilation: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), liveDate: (0, utils_1.dateAddDays)(0), bookId: seedBooks.Annihilation.id, userId: seedArcTeams.DrewKarpyshynFans.userId, arcTeamId: seedArcTeams.DrewKarpyshynFans.id, specialInformation: 'Annihilation Arc', reviewFlags: FLAGS.AMAZON, optionalReviewFlags: FLAGS.BARNES | FLAGS.KOBO, requiredReviewCount: 2, userClaims: [
                    {
                        userId: seedUsers.StanRizzo.id,
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 4, review: 'Annihilation was great!', privateFeedback: 'Some Annihilation private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.KOBO, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE })
                            ]
                        }
                    },
                    {
                        userId: seedUsers.HanSolo.id
                    },
                    {
                        userId: seedUsers.MaraJade.id,
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 4, review: 'Annihilation was great again!', privateFeedback: 'Some Annihilation private feedback from Mara' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.KOBO, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE })
                            ]
                        }
                    }
                ] }),
            Allegiance_NeverEnding10Day: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.Allegiance.id, userId: seedArcTeams.TimothyZahnFans.userId, arcTeamId: seedArcTeams.TimothyZahnFans.id, specialInformation: 'Allegiance Arc', reviewFlags: FLAGS.AMAZON | FLAGS.GOOD_READS | FLAGS.GOOGLE | FLAGS.BOOK_BUB, requiredReviewCount: 1 }),
            ChoicesOfOne: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), liveDate: (0, utils_1.dateAddDays)(0), bookId: seedBooks.ChoicesOfOne.id, userId: seedArcTeams.TimothyZahnFans.userId, arcTeamId: seedArcTeams.TimothyZahnFans.id, specialInformation: 'ChoicesOfOne Arc', userClaims: [
                    {
                        userId: seedUsers.StanRizzo.id,
                        createdDate: (0, utils_1.dateMinusDays)(14)
                    },
                    {
                        userId: seedUsers.ClaimNonMemberArcDownloadsLocked.id,
                        skipEligibleCheck: true
                    }
                ] }),
            DarkForceRising: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.DarkForceRising.id, userId: seedArcTeams.TimothyZahnFans.userId, arcTeamId: seedArcTeams.TimothyZahnFans.id, specialInformation: 'DarkForceRising Arc', reviewFlags: FLAGS.BOOK_BUB | FLAGS.AMAZON | FLAGS.ITUNES }),
            Darksaber: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.DarkSaber.id, userId: seedArcTeams.TeamForTheLightSide.userId, arcTeamId: seedArcTeams.TeamForTheLightSide.id, specialInformation: 'Darksaber Arc', liveDate: (0, utils_1.dateMinusDays)(14), vipExclusiveAccessDays: 4, skipAudioBookCreation: true, userClaims: [
                    {
                        userId: seedUsers.ReviewerNoName.id
                    }
                ] }),
            Deceived: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.Deceived.id, userId: seedArcTeams.TeamForTheDarkSide.userId, arcTeamId: seedArcTeams.TeamForTheDarkSide.id, specialInformation: 'Deceived Arc' }),
            HeirToTheEmpire: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.HeirToTheEmpire.id, userId: seedArcTeams.TimothyZahnFans.userId, arcTeamId: seedArcTeams.TimothyZahnFans.id, specialInformation: 'HeirToTheEmpire Arc' }),
            LostTribeOfTheSith: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.LostTribeOfTheSith.id, userId: seedArcTeams.TeamForTheDarkSide.userId, arcTeamId: seedArcTeams.TeamForTheDarkSide.id, specialInformation: 'LostTribeOfTheSith Arc' }),
            OutboundFlight: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.OutboundFlight.id, userId: seedArcTeams.TimothyZahnFans.userId, arcTeamId: seedArcTeams.TimothyZahnFans.id, specialInformation: 'OutboundFlight Arc' }),
            PathOfDestruction: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.PathOfDestruction.id, userId: seedArcTeams.DrewKarpyshynFans.userId, arcTeamId: seedArcTeams.DrewKarpyshynFans.id, specialInformation: 'PathOfDestruction Arc' }),
            RuleOfTwo: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.RuleOfTwo.id, userId: seedArcTeams.DrewKarpyshynFans.userId, arcTeamId: seedArcTeams.DrewKarpyshynFans.id, specialInformation: 'RuleOfTwo Arc' }),
            TheLastCommand: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.TheLastCommand.id, userId: seedArcTeams.TimothyZahnFans.userId, arcTeamId: seedArcTeams.TimothyZahnFans.id, specialInformation: 'TheLastCommand Arc', seriesLimitType: module_constants_1.bsConstants.ARCS.SERIES_LIMIT_TYPE.PREVIOUS_REVIEWERS }),
            GrowingAuthorArc1: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.GrowingAuthorBook1.id, userId: seedArcTeams.GrowingAuthorTeam.userId, arcTeamId: seedArcTeams.GrowingAuthorTeam.id, specialInformation: 'Growing Author ARC', liveDate: (0, utils_1.dateMinusDays)(14), stopDownloadDate: (0, utils_1.dateAddDays)(46), dueDate: (0, utils_1.dateAddDays)(60), reviewDays: void 0, skipAudioBookCreation: true }),
            ProAuthorArc1: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.ProAuthorBook1.id, userId: seedArcTeams.ProAuthorTeam.userId, arcTeamId: seedArcTeams.ProAuthorTeam.id, specialInformation: 'Pro Author ARC 1', liveDate: (0, utils_1.dateAddDays)(46), stopDownloadDate: (0, utils_1.dateAddDays)(60), dueDate: (0, utils_1.dateAddDays)(14), reviewDays: void 0 }),
            ProAuthorArc2: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.ProAuthorBook1.id, userId: seedArcTeams.ProAuthorTeam.userId, arcTeamId: seedArcTeams.ProAuthorTeam.id, specialInformation: 'Pro Author ARC 2', reviewFlags: FLAGS.AMAZON, requiredReviewCount: 1, liveDate: (0, utils_1.dateMinusDays)(14), stopDownloadDate: (0, utils_1.dateAddDays)(46), dueDate: (0, utils_1.dateAddDays)(60), reviewDays: void 0, skipAudioBookCreation: true }),
            ProAuthorArc3: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.ProAuthorBook1.id, userId: seedArcTeams.ProAuthorTeam.userId, arcTeamId: seedArcTeams.ProAuthorTeam.id, specialInformation: 'Pro Author ARC 3', reviewFlags: FLAGS.AMAZON, requiredReviewCount: 1, liveDate: (0, utils_1.dateMinusDays)(7), stopDownloadDate: (0, utils_1.dateAddDays)(53), dueDate: (0, utils_1.dateAddDays)(60), reviewDays: void 0, skipAudioBookCreation: true }),
            ProAuthorArc4: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.ProAuthorBook1.id, userId: seedArcTeams.ProAuthorTeam.userId, arcTeamId: seedArcTeams.ProAuthorTeam.id, specialInformation: 'Pro Author ARC 4', reviewFlags: FLAGS.AMAZON, requiredReviewCount: 1, liveDate: (0, utils_1.dateAddDays)(0), stopDownloadDate: (0, utils_1.dateAddDays)(60), dueDate: (0, utils_1.dateAddDays)(60), reviewDays: void 0, skipAudioBookCreation: true }),
            BestSellerAuthorArc1: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.BestSellerAuthorBook1.id, userId: seedArcTeams.BestSellerAuthorTeam.userId, arcTeamId: seedArcTeams.BestSellerAuthorTeam.id, specialInformation: 'BestSeller Author ARC 1', liveDate: (0, utils_1.dateAddDays)(46), stopDownloadDate: (0, utils_1.dateAddDays)(60), dueDate: (0, utils_1.dateAddDays)(67), reviewDays: void 0 }),
            BestSellerAuthorArc2: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.BestSellerAuthorBook1.id, userId: seedArcTeams.BestSellerAuthorTeam.userId, arcTeamId: seedArcTeams.BestSellerAuthorTeam.id, specialInformation: 'BestSeller Author ARC 2', reviewFlags: FLAGS.AMAZON, requiredReviewCount: 1, liveDate: (0, utils_1.dateMinusDays)(14), stopDownloadDate: (0, utils_1.dateAddDays)(46), dueDate: (0, utils_1.dateAddDays)(60), reviewDays: void 0, skipAudioBookCreation: true }),
            BestSellerAuthorArc3: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.BestSellerAuthorBook1.id, userId: seedArcTeams.BestSellerAuthorTeam.userId, arcTeamId: seedArcTeams.BestSellerAuthorTeam.id, specialInformation: 'BestSeller Author ARC 3', reviewFlags: FLAGS.AMAZON, requiredReviewCount: 1, liveDate: (0, utils_1.dateMinusDays)(7), stopDownloadDate: (0, utils_1.dateAddDays)(53), dueDate: (0, utils_1.dateAddDays)(60), reviewDays: void 0, skipAudioBookCreation: true }),
            BestSellerAuthorArc4: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.BestSellerAuthorBook2.id, userId: seedArcTeams.BestSellerAuthorTeam.userId, arcTeamId: seedArcTeams.BestSellerAuthorTeam.id, specialInformation: 'BestSeller Author ARC 4', reviewFlags: FLAGS.AMAZON, requiredReviewCount: 1, liveDate: (0, utils_1.dateMinusDays)(7), stopDownloadDate: (0, utils_1.dateAddDays)(53), dueDate: (0, utils_1.dateAddDays)(60), reviewDays: void 0, skipAudioBookCreation: true }),
            BestSellerAuthorArc5: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.BestSellerAuthorBook1.id, userId: seedArcTeams.BestSellerAuthorTeam.userId, arcTeamId: seedArcTeams.BestSellerAuthorTeam.id, specialInformation: 'BestSeller Author ARC 5', reviewFlags: FLAGS.AMAZON, requiredReviewCount: 1, liveDate: (0, utils_1.dateAddDays)(0), stopDownloadDate: (0, utils_1.dateAddDays)(60), dueDate: (0, utils_1.dateAddDays)(60), reviewDays: void 0, skipAudioBookCreation: true }),
            BestSellerAuthorArc6: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.BestSellerAuthorBook2.id, userId: seedArcTeams.BestSellerAuthorTeam.userId, arcTeamId: seedArcTeams.BestSellerAuthorTeam.id, specialInformation: 'BestSeller Author ARC 6', reviewFlags: FLAGS.AMAZON, requiredReviewCount: 1, liveDate: (0, utils_1.dateAddDays)(0), stopDownloadDate: (0, utils_1.dateAddDays)(60), dueDate: (0, utils_1.dateAddDays)(60), reviewDays: void 0, skipAudioBookCreation: true }),
            PublisherArc1: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.PublisherBook1.id, userId: seedArcTeams.PublisherTeam.userId, arcTeamId: seedArcTeams.PublisherTeam.id, specialInformation: 'Publisher Author ARC 1' }),
            ReviewDueSoonArc: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.ReviewDueSoon.id, userId: seedArcTeams.ReviewDueSoonTeam.userId, arcTeamId: seedArcTeams.ReviewDueSoonTeam.id, specialInformation: 'DarkForceRising Arc', reviewFlags: FLAGS.BOOK_BUB, requiredReviewCount: 1, liveDate: (0, utils_1.newDate)(), reviewDays: 1, userClaims: [
                    {
                        userId: seedUsers.HasReviewDueSoon.id
                    }
                ] }),
            FinishedArc: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.FinishedArcBook.id, userId: seedArcTeams.PlaceholderTeamOne.userId, arcTeamId: seedArcTeams.PlaceholderTeamOne.id, specialInformation: 'Finished ARC', reviewFlags: FLAGS.BOOK_BUB, liveDate: (0, utils_1.dateMinusDays)(90), stopDownloadDate: (0, utils_1.dateMinusDays)(30), dueDate: (0, utils_1.dateMinusDays)(25), reviewDays: 1, neverEndingPrivate: false, skipAudioBookCreation: true, userClaims: [
                    {
                        userId: seedUsers.UserAlreadyClaimedBookBefore.id,
                        createdDate: (0, utils_1.dateMinusDays)(25),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.BOOK_BUB, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE })
                            ]
                        }
                    }
                ] }),
            DraftArc: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.DraftArcBook.id, userId: seedArcTeams.PlaceholderTeamTwo.userId, arcTeamId: void 0 // will be marked as draft
             }),
            FutureArc: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.FutureArc)), { liveDate: (0, utils_1.dateAddDays)(21), reviewDays: 10, neverEndingPrivate: false, stopDownloadDate: (0, utils_1.dateAddDays)(60), dueDate: (0, utils_1.dateAddDays)(67) }),
            NoCopiesLeft: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.NoCopiesLeft)), { copyCountType: module_constants_1.bsConstants.ARCS.COPY_COUNT_TYPE.LIMITED, copyCount: 1, userClaims: [{
                        userId: seedUsers.MaraJade.id,
                        createdDate: (0, utils_1.dateMinusDays)(14)
                    }] // 1 claim, 1 copy count - should mean no copies left
             }),
            BlockedFromTeam: Object.assign({}, this.genericArc(this.getNextId(), seedBooks.BlockedFromTeam, seedArcTeams.StanBlocked)),
            ReviewedBookBefore: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.ReviewedBookBefore)), { userClaims: [
                    {
                        userId: seedUsers.StanRizzo.id,
                        createdDate: (0, utils_1.dateMinusDays)(14),
                        review: this.genericApprovedReview()
                    }
                ] }),
            NotReviewedBefore: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.NotReviewedBefore)), { participationPreventionType: module_constants_1.bsConstants.ARCS.PARTICIPATION_PREVENTION_TYPE.NONE }),
            HasCopiesLeft: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.HasCopiesLeft)), { copyCount: 10, userClaims: [{
                        userId: seedUsers.MaraJade.id,
                        createdDate: (0, utils_1.dateMinusDays)(14)
                    }] // 1 claim, 1 copy count - should mean no copies left
             }),
            AudienceGeneral: Object.assign({}, this.genericArc(this.getNextId(), seedBooks.AudienceGeneral)),
            AudienceTeen: Object.assign({}, this.genericArc(this.getNextId(), seedBooks.AudienceTeen)),
            AudienceMature: Object.assign({}, this.genericArc(this.getNextId(), seedBooks.AudienceMature)),
            AudienceExplicit: Object.assign({}, this.genericArc(this.getNextId(), seedBooks.AudienceExplicit)),
            StoryShort: Object.assign({}, this.genericArc(this.getNextId(), seedBooks.StoryShort)),
            StoryNovella: Object.assign({}, this.genericArc(this.getNextId(), seedBooks.StoryNovella)),
            StoryNovel: Object.assign({}, this.genericArc(this.getNextId(), seedBooks.StoryNovel)),
            BookNotOnBooksproutBefore: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.BookNotOnBooksproutBefore)), { participationPreventionType: module_constants_1.bsConstants.ARCS.PARTICIPATION_PREVENTION_TYPE.NONE }),
            BookHasRunBeforePreviousRun: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.BookHasRunBefore)), { liveDate: (0, utils_1.dateMinusDays)(60), stopDownloadDate: (0, utils_1.dateMinusDays)(46), dueDate: (0, utils_1.dateMinusDays)(30), userClaims: [{
                        userId: seedUsers.MaraJade.id,
                        createdDate: (0, utils_1.dateMinusDays)(14)
                    }] }),
            BookHasRunBefore: Object.assign({}, this.genericArc(this.getNextId(), seedBooks.BookHasRunBefore)),
            FollowingAuthor: Object.assign({}, this.genericArc(this.getNextId(), seedBooks.FollowingAuthor)),
            PartOfArcTeam: Object.assign({}, this.genericArc(this.getNextId(), seedBooks.PartOfArcTeam, seedArcTeams.StanRegular)),
            HadPreviousPublic: Object.assign({}, this.genericArc(this.getNextId(), seedBooks.BookHasRunBefore)),
            WithPastReleaseDate: Object.assign({}, this.genericArc(this.getNextId(), seedBooks.WithPastReleaseDate)),
            ExclusiveToTopReviewers: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.ExclusiveToTopReviewers)), { participationType: module_constants_1.bsConstants.ARCS.PARTICIPATION_TYPE.TOP_REVIEWERS }),
            ComplicatedSites: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.ComplicatedSites)), { reviewFlags: FLAGS.AMAZON |
                    FLAGS.SMASH_WORDS |
                    FLAGS.KOBO |
                    FLAGS.BOOK_BUB, optionalReviewFlags: FLAGS.GOOD_READS |
                    FLAGS.GOOGLE, requiredReviewCount: 5 }),
            Complete: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.Complete)), { stopDownloadDate: (0, utils_1.dateMinusDays)(1), dueDate: (0, utils_1.dateMinusDays)(1), userClaims: [{
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(30),
                        review: this.genericApprovedReview()
                    }] }),
            Cancelled: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.Cancelled)), { userClaims: [{
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        cancellationType: module_constants_1.bsConstants.ARCS.CLAIMS.CANCELLATION_TYPE.PERSONAL,
                        cancellationReason: 'Stan test cancel'
                    }] }),
            PendingPublishing: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.PendingPublishing)), { reviewFlags: FLAGS.AMAZON |
                    FLAGS.GOOGLE |
                    FLAGS.BOOK_BUB, userClaims: [{
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOGLE, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE })
                            ]
                        }
                    }] }),
            PendingPublishingWithOptionalStores: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.PendingPublishingWithOptionalStores)), { reviewFlags: FLAGS.AMAZON, optionalReviewFlags: FLAGS.GOOGLE |
                    FLAGS.BOOK_BUB, requiredReviewCount: 2, userClaims: [{
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 4, review: 'A 4 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE })
                            ]
                        }
                    }] }),
            Active: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.Active)), { participationPreventionType: module_constants_1.bsConstants.ARCS.PARTICIPATION_PREVENTION_TYPE.NONE, userClaims: [{
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7)
                    }] }),
            Due: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.Due)), { stopDownloadDate: (0, utils_1.dateMinusDays)(1), dueDate: (0, utils_1.newDate)(), reviewFlags: FLAGS.AMAZON |
                    FLAGS.GOOGLE, optionalReviewFlags: FLAGS.GOOD_READS |
                    FLAGS.ITUNES, requiredReviewCount: 3, userClaims: [{
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 4, review: 'A 4 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOGLE, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE, link: '' }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOD_READS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.ITUNES, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE })
                            ]
                        }
                    }] }),
            DueOneDelayedOtherAvailable: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.DueOneDelayedOtherAvailable)), { reviewFlags: FLAGS.AMAZON |
                    FLAGS.GOOGLE, userClaims: [{
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.DELAYED }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOGLE, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE, link: '' })
                            ]
                        }
                    }] }),
            Delayed: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.Delayed)), { reviewFlags: FLAGS.AMAZON |
                    FLAGS.GOOGLE, optionalReviewFlags: FLAGS.SMASH_WORDS, requiredReviewCount: 2, userClaims: [{
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.DELAYED }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOGLE, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.SMASH_WORDS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE })
                            ]
                        }
                    }] }),
            OneDelayedOneWaitingLinkOneReady: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.OneDelayedOneWaitingLinkOneReady)), { reviewFlags: FLAGS.AMAZON |
                    FLAGS.GOOGLE, optionalReviewFlags: FLAGS.SMASH_WORDS |
                    FLAGS.BOOK_BUB, requiredReviewCount: 3, userClaims: [{
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 5, review: 'A 5 star review from Your Arcs', privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.DELAYED }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOGLE, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.SMASH_WORDS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE, link: '' })
                            ]
                        }
                    }] }),
            Private: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.Private)), { neverEndingPrivate: true, discoverableType: module_constants_1.bsConstants.ARCS.DISCOVERABLE_TYPE.TEAM_ONLY, participationType: module_constants_1.bsConstants.ARCS.PARTICIPATION_TYPE.ON_TEAM }),
            DueOnGeneralTeam: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.Private)), { liveDate: (0, utils_1.dateMinusDays)(30), stopDownloadDate: (0, utils_1.dateMinusDays)(16), reviewDays: 1, neverEndingPrivate: false, dueDate: (0, utils_1.dateMinusDays)(15), userClaims: [{
                        userId: seedUsers.ClaimOutstandingTeamReviewNotVIP.id,
                        createdDate: (0, utils_1.dateMinusDays)(21)
                    }, {
                        userId: seedUsers.ClaimNonMemberTooManyDue.id,
                        createdDate: (0, utils_1.dateMinusDays)(20),
                        skipEligibleCheck: true
                    }] }),
            AllSitesWithLinks: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.AllSitesWithLinks)), { reviewFlags: FLAGS.AMAZON, requiredReviewCount: 5, optionalReviewFlags: FLAGS.SMASH_WORDS |
                    FLAGS.KOBO |
                    FLAGS.BOOK_BUB |
                    FLAGS.GOOD_READS |
                    FLAGS.BARNES |
                    FLAGS.GOOGLE |
                    FLAGS.ITUNES, userClaims: [{
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(14),
                        review: this.genericApprovedReview()
                    }] }),
            SeriesBook1: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.SeriesBook1)), { reviewFlags: FLAGS.AMAZON |
                    FLAGS.GOOGLE, optionalReviewFlags: FLAGS.SMASH_WORDS |
                    FLAGS.BOOK_BUB, requiredReviewCount: 3, seriesLimitType: 2 }),
            SeriesBook2: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.SeriesBook2)), { reviewFlags: FLAGS.AMAZON |
                    FLAGS.GOOGLE, optionalReviewFlags: FLAGS.SMASH_WORDS |
                    FLAGS.BOOK_BUB, requiredReviewCount: 3, seriesLimitType: 2 }),
            BlockedFromTeamAndReported: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.BlockedFromTeamAndReported, seedArcTeams.PlaceholderTeamOne)), { reviewFlags: FLAGS.AMAZON |
                    FLAGS.GOOGLE, optionalReviewFlags: FLAGS.SMASH_WORDS |
                    FLAGS.BOOK_BUB, requiredReviewCount: 3, userClaims: [
                    {
                        userId: seedUsers.BadBoy.id,
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 5, review: 'A 5 star review from Bad Boy: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Bad Boy feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOGLE, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.SMASH_WORDS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE, link: '' })
                            ]
                        },
                        report: {
                            type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS_REASON_TYPE.HARASSMENT,
                            reason: 'Why, oh why?',
                            reportToStaff: true
                        }
                    }
                ] }),
            TooManyCancellationsReported: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.TooManyCancellationsReported)), { userClaims: [
                    {
                        userId: seedUsers.BadBoy.id,
                        cancellationType: module_constants_1.bsConstants.ARCS.CLAIMS.CANCELLATION_TYPE.BAD_FIT,
                        cancellationReason: this.faker.lorem.paragraph().substring(0, 150),
                        additionalInformation: this.faker.lorem.paragraph().substring(0, 150),
                        report: {
                            type: module_constants_1.bsConstants.ARC_TEAMS.MEMBERS.STATUS_REASON_TYPE.CANCELLED,
                            reason: this.faker.lorem.paragraph().substring(0, 150),
                            reportToStaff: true
                        }
                    }
                ] }),
            NotificationAuthor: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.NotificationAuthor, seedArcTeams.TeamNotify)), { liveDate: (0, utils_1.dateMinusDays)(1), reviewFlags: FLAGS.AMAZON | FLAGS.BOOK_BUB | FLAGS.SMASH_WORDS, stopDownloadDate: (0, utils_1.dateMinusDays)(2), dueDate: (0, utils_1.dateAddDays)(2), skipAudioBookCreation: true }),
            NotificationAuthorTimeToPublish: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.NotificationAuthorTimeToPublish, seedArcTeams.TeamNotify)), { liveDate: (0, utils_1.dateMinusDays)(10), reviewFlags: FLAGS.AMAZON | FLAGS.BOOK_BUB | FLAGS.SMASH_WORDS, stopDownloadDate: (0, utils_1.dateMinusDays)(1), dueDate: (0, utils_1.dateMinusDays)(0), skipAudioBookCreation: true }),
            NotificationDelayExpiring: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.NotificationDelayExpiring, seedArcTeams.TeamNotify)), { liveDate: (0, utils_1.dateMinusDays)(30), stopDownloadDate: (0, utils_1.dateMinusDays)(20), dueDate: (0, utils_1.dateMinusDays)(13), skipAudioBookCreation: true, userClaims: [
                    {
                        userId: seedUsers.NotificationReviewer.id,
                        skipEligibleCheck: true,
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 4, review: 'Notification Expiring Review', privateFeedback: 'Notification Expiring Review Private Feedback' }),
                            sites: [Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.DELAYED, statusDate: (0, utils_1.dateMinusDays)(6) })]
                        }
                    }
                ] }),
            NotificationDelayExpired: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.NotificationDelayExpired, seedArcTeams.TeamNotify)), { liveDate: (0, utils_1.dateMinusDays)(30), stopDownloadDate: (0, utils_1.dateMinusDays)(20), dueDate: (0, utils_1.dateMinusDays)(13), skipAudioBookCreation: true, userClaims: [
                    {
                        userId: seedUsers.NotificationReviewer.id,
                        skipEligibleCheck: true,
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 4, review: 'Notification Expired Review', privateFeedback: 'Notification Expired Review Private Feedback' }),
                            sites: [Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.DELAYED, statusDate: (0, utils_1.dateMinusDays)(14) })]
                        }
                    }
                ] }),
            NotificationReadyToReviewEverywhere: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.NotificationReadyToReviewEverywhere, seedArcTeams.TeamNotify)), { liveDate: (0, utils_1.dateMinusDays)(10), stopDownloadDate: (0, utils_1.dateMinusDays)(1), dueDate: (0, utils_1.dateMinusDays)(0), skipAudioBookCreation: true, userClaims: [
                    {
                        userId: seedUsers.NotificationReviewer.id,
                        skipEligibleCheck: true
                    }
                ] }),
            NotificationDueSoon: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.NotificationDueSoon, seedArcTeams.TeamNotify)), { liveDate: (0, utils_1.dateMinusDays)(10), stopDownloadDate: (0, utils_1.dateMinusDays)(0), dueDate: (0, utils_1.dateAddDays)(2), skipAudioBookCreation: true, 
                // don't add review, otherwise notification won't generate
                userClaims: [
                    {
                        userId: seedUsers.NotificationReviewer.id,
                        createdDate: (0, utils_1.dateMinusDays)(10),
                        skipEligibleCheck: true
                    }
                ] }),
            // everything is same as for previous arc except the due date
            NotificationDueSoonShouldNotGenerate: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.NotificationDueSoonShouldNotGenerate, seedArcTeams.TeamNotify)), { liveDate: (0, utils_1.dateMinusDays)(10), stopDownloadDate: (0, utils_1.dateMinusDays)(0), dueDate: (0, utils_1.dateAddDays)(3), skipAudioBookCreation: true, userClaims: [
                    {
                        userId: seedUsers.NotificationReviewer.id,
                        createdDate: (0, utils_1.dateMinusDays)(10),
                        skipEligibleCheck: true
                    }
                ] }),
            NotificationDeletedArc: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.DeletedBook, seedArcTeams.DeletedTeam)), { deleted: true, userClaims: [
                    {
                        userId: seedUsers.NotificationReviewer.id
                    }
                ] }),
            HasReportedPirate: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.HasReportedPirate)), { skipAudioBookCreation: true, reportPirateArgs: {
                    bookId: seedBooks.HasReportedPirate.id + '',
                    link: 'https://www.test.com',
                    fileName: 'test'
                }, pirateId: seedUsers.Pirate.pirateId }),
            HasReviews: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.HasReviews)), { skipAudioBookCreation: true, liveDate: (0, utils_1.dateMinusDays)(10), stopDownloadDate: (0, utils_1.dateMinusDays)(1), dueDate: (0, utils_1.dateMinusDays)(0), reviewFlags: FLAGS.AMAZON |
                    FLAGS.GOOGLE |
                    FLAGS.BOOK_BUB |
                    FLAGS.GOOD_READS, optionalReviewFlags: FLAGS.SMASH_WORDS, requiredReviewCount: 2, userClaims: [{
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(7), rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOGLE, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.BOOK_BUB, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOD_READS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.SMASH_WORDS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE })
                            ]
                        }
                    }, {
                        userId: seedUsers.Thrawn.id,
                        createdDate: (0, utils_1.dateMinusDays)(6),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(6), rating: 4, review: 'A 4 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.REJECTED, statusReason: 'Amazon rejected review' }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOGLE, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.BOOK_BUB, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE, link: '' }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOD_READS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.SMASH_WORDS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE, link: '' })
                            ]
                        }
                    }, {
                        userId: seedUsers.StanRizzo.id,
                        createdDate: (0, utils_1.dateMinusDays)(5),
                        state: module_constants_1.bsConstants.ARCS.CLAIMS.STATE.NEW_ACTIVITY,
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(5), rating: 3, review: 'A 3 star review: KEEP THE TEXT CONSTANT SO TESTS CAN VERIFY IT', privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.DELAYED }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOGLE, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE, link: '' }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.BOOK_BUB, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE, link: '' }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOD_READS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.SMASH_WORDS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE, link: '' })
                            ]
                        }
                    }, {
                        userId: seedUsers.HanSolo.id,
                        createdDate: (0, utils_1.dateMinusDays)(4),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(4), rating: 2, review: 'A 2 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.DELAYED }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOGLE, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE, link: '' }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.BOOK_BUB, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOD_READS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.SMASH_WORDS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE, link: '' })
                            ]
                        }
                    }, {
                        userId: seedUsers.DarthVadar.id,
                        createdDate: (0, utils_1.dateMinusDays)(3),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(3), rating: 1, review: 'A 1 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.DELAYED }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOGLE, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.BOOK_BUB, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOD_READS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.SMASH_WORDS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE, link: '' })
                            ]
                        }
                    }, {
                        userId: seedUsers.RandomUsers[0].id
                    }, {
                        userId: seedUsers.RandomUsers[1].id,
                        cancellationType: module_constants_1.bsConstants.ARCS.CLAIMS.CANCELLATION_TYPE.OTHER,
                        cancellationReason: this.faker.lorem.paragraph().substring(0, 150),
                        additionalInformation: this.faker.lorem.paragraph().substring(0, 150)
                    }] }),
            OptionalStores: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.OptionalStores)), { skipAudioBookCreation: true, reviewFlags: FLAGS.NONE, optionalReviewFlags: FLAGS.AMAZON }),
            Scheduled: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.Scheduled.id, userId: seedArcTeams.TimothyZahnFans.userId, arcTeamId: seedArcTeams.TimothyZahnFans.id, specialInformation: 'Scheduled Arc', reviewFlags: FLAGS.AMAZON, optionalReviewFlags: FLAGS.BARNES | FLAGS.KOBO, requiredReviewCount: 2 }),
            ComplexOverdueAmazonDelayedOneComplete: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.ComplexOverdueAmazonDelayedOneComplete)), { specialInformation: 'Complex Overdue ARC', reviewFlags: FLAGS.AMAZON | FLAGS.GOOD_READS, requiredReviewCount: 2, liveDate: (0, utils_1.dateMinusDays)(21), stopDownloadDate: (0, utils_1.dateMinusDays)(5), dueDate: (0, utils_1.dateMinusDays)(4), userClaims: [{
                        userId: seedUsers.ComplexOverdue.id,
                        createdDate: (0, utils_1.dateMinusDays)(15),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(7), rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.DELAYED }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOD_READS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE })
                            ]
                        }
                    }] }),
            ComplexOverdueArc5Days: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.ComplexOverdueBook5Days)), { specialInformation: 'Complex Overdue ARC by 5 days', reviewFlags: FLAGS.GOOD_READS, requiredReviewCount: 1, liveDate: (0, utils_1.dateMinusDays)(21), stopDownloadDate: (0, utils_1.dateMinusDays)(10), dueDate: (0, utils_1.dateMinusDays)(9), userClaims: [{
                        userId: seedUsers.ComplexOverdue.id,
                        createdDate: (0, utils_1.dateMinusDays)(15),
                        skipEligibleCheck: true
                    }, {
                        userId: seedUsers.ClaimNonMemberTooManyDue.id,
                        createdDate: (0, utils_1.dateMinusDays)(11),
                        skipEligibleCheck: true
                    }] }),
            ComplexOverdueArc4Days: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.ComplexOverdueBook4Days)), { specialInformation: 'Complex Overdue ARC by 4 days', reviewFlags: FLAGS.GOOGLE, requiredReviewCount: 1, liveDate: (0, utils_1.dateMinusDays)(21), stopDownloadDate: (0, utils_1.dateMinusDays)(7), dueDate: (0, utils_1.dateMinusDays)(6), userClaims: [{
                        userId: seedUsers.ComplexOverdue.id,
                        skipEligibleCheck: true,
                        createdDate: (0, utils_1.dateMinusDays)(15),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(7), rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' })
                        }
                    }, {
                        userId: seedUsers.ClaimNonMemberTooManyDue.id,
                        createdDate: (0, utils_1.dateMinusDays)(20),
                        skipEligibleCheck: true
                    }] }),
            ComplexOverdueArcToday: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.ComplexOverdueBookToday)), { specialInformation: 'Complex Overdue Due Today', reviewFlags: FLAGS.GOOD_READS, requiredReviewCount: 1, liveDate: (0, utils_1.dateMinusDays)(21), stopDownloadDate: (0, utils_1.startOfDay)(new Date()), dueDate: (0, utils_1.startOfDay)(new Date()), userClaims: [{
                        userId: seedUsers.ComplexOverdue.id,
                        createdDate: (0, utils_1.dateMinusDays)(5),
                        skipEligibleCheck: true
                    }] }),
            ComplexOverdueArcYesterday: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.ComplexOverdueBookYesterday)), { specialInformation: 'Complex Overdue Due Yesterday', reviewFlags: FLAGS.GOOD_READS, requiredReviewCount: 1, liveDate: (0, utils_1.dateMinusDays)(21), stopDownloadDate: (0, utils_1.dateMinusDays)(2), dueDate: (0, utils_1.dateMinusDays)(1), userClaims: [{
                        userId: seedUsers.ComplexOverdue.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        skipEligibleCheck: true
                    }] }),
            ComplexOverdueArcInFuture: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.ComplexOverdueBookInFuture)), { specialInformation: 'Complex Overdue Due In Future', reviewFlags: FLAGS.GOOD_READS, requiredReviewCount: 1, liveDate: (0, utils_1.dateMinusDays)(21), stopDownloadDate: (0, utils_1.dateMinusDays)(12), dueDate: (0, utils_1.dateAddDays)(11), userClaims: [{
                        userId: seedUsers.ComplexOverdue.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        skipEligibleCheck: true
                    }] }),
            ComplexOverdueArc4DaysAwaitingPublish: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.ComplexOverdueBook4DaysAwaitingPublish)), { specialInformation: 'Complex Overdue ARC by 4 days but awaiting publish', reviewFlags: FLAGS.KOBO, requiredReviewCount: 1, liveDate: (0, utils_1.dateMinusDays)(21), stopDownloadDate: (0, utils_1.dateMinusDays)(4), dueDate: (0, utils_1.dateMinusDays)(3), userClaims: [{
                        userId: seedUsers.ComplexOverdue.id,
                        skipEligibleCheck: true,
                        createdDate: (0, utils_1.dateMinusDays)(15),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(7), rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' })
                        }
                    }] }),
            AllSitesNoLinks: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.AllSitesNoLinks)), { reviewFlags: FLAGS.AMAZON, requiredReviewCount: 2, optionalReviewFlags: FLAGS.SMASH_WORDS |
                    FLAGS.KOBO |
                    FLAGS.BOOK_BUB |
                    FLAGS.GOOD_READS |
                    FLAGS.BARNES |
                    FLAGS.GOOGLE |
                    FLAGS.ITUNES }),
            OutstandingReviewWithClaim: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.OutstandingReviewWithClaim)), { arcTeamId: seedArcTeams.OutstandingReviewTeam.id, specialInformation: 'Outstanding review with claimed arc', liveDate: (0, utils_1.dateMinusDays)(21), stopDownloadDate: (0, utils_1.dateMinusDays)(4), dueDate: (0, utils_1.dateMinusDays)(1), skipAudioBookCreation: true, userClaims: [{
                        userId: seedUsers.ClaimNonMemberOutstandingReviewForThisTeam.id,
                        createdDate: (0, utils_1.dateMinusDays)(15)
                    }, {
                        // delayed review on other claim is required, so we can check the bug is fixed
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(9),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.DELAYED })
                            ]
                        }
                    }] }),
            OutstandingReviewToBeClaimed: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.OutstandingReviewToBeClaimed)), { arcTeamId: seedArcTeams.OutstandingReviewTeam.id, specialInformation: 'Outstanding review to be claimed arc', liveDate: (0, utils_1.dateMinusDays)(0), stopDownloadDate: (0, utils_1.dateAddDays)(7), dueDate: (0, utils_1.dateAddDays)(11), skipAudioBookCreation: true }),
            AuthorsDirectAndKoboAudiobook: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.AuthorsDirectAndKoboAudiobook)), { specialInformation: 'Authors Direct and Kobo audiobook', liveDate: (0, utils_1.dateMinusDays)(0), stopDownloadDate: (0, utils_1.dateAddDays)(7), dueDate: (0, utils_1.dateAddDays)(11), skipEbookCreation: true, isAuthorsDirectOrKoboAudiobook: true, reviewFlags: FLAGS.AUDIO_KOBO | FLAGS.AUDIO_BOOK_BUB }),
            RequiredSiteIsDelayedWithOptionalSitesDone: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.RequiredSiteIsDelayedWithOptionalSitesDone)), { skipAudioBookCreation: true, liveDate: (0, utils_1.dateMinusDays)(10), stopDownloadDate: (0, utils_1.dateMinusDays)(1), dueDate: (0, utils_1.dateMinusDays)(0), reviewFlags: FLAGS.AMAZON |
                    FLAGS.GOOD_READS, optionalReviewFlags: FLAGS.GOOGLE |
                    FLAGS.KOBO |
                    FLAGS.BOOK_BUB |
                    FLAGS.ITUNES |
                    FLAGS.BARNES, requiredReviewCount: 2, userClaims: [{
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(7), rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.DELAYED }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.GOOD_READS, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.BOOK_BUB, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.ITUNES, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE }),
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.BARNES, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE })
                            ]
                        }
                    }] }),
            TwoArcsRunningEBookAndAudioBookE: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.BookToRunAudioAndEBook, seedArcTeams.EbookAndAudioBook)), { liveDate: (0, utils_1.dateMinusDays)(30), stopDownloadDate: (0, utils_1.dateMinusDays)(20), neverEndingPrivate: false, dueDate: (0, utils_1.dateMinusDays)(14), skipAudioBookCreation: true, userClaims: [{
                        userId: seedUsers.EbookAndAudioBookReviewer.id,
                        createdDate: (0, utils_1.dateMinusDays)(25),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(22), rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                            sites: [
                                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE })
                            ]
                        }
                    }] }),
            TwoArcsRunningEBookAndAudioBookA: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.BookToRunAudioAndEBook, seedArcTeams.EbookAndAudioBook)), { liveDate: (0, utils_1.dateMinusDays)(14), stopDownloadDate: (0, utils_1.dateAddDays)(14), neverEndingPrivate: true, dueDate: (0, utils_1.dateAddDays)(20), skipEbookCreation: true, arcType: module_constants_1.bsConstants.ARCS.TYPE.AUDIO_BOOK }),
            DueForTooLongArc: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.DueForTooLong.id, userId: seedArcTeams.PlaceholderTeamOne.userId, arcTeamId: seedArcTeams.PlaceholderTeamOne.id, specialInformation: 'DueForTooLong ARC', reviewFlags: FLAGS.AMAZON, liveDate: (0, utils_1.dateMinusDays)(180), stopDownloadDate: (0, utils_1.dateMinusDays)(160), dueDate: (0, utils_1.dateMinusDays)(155), neverEndingPrivate: false, skipAudioBookCreation: true, userClaims: [
                    {
                        userId: seedUsers.ClaimNonMemberDueTooLong.id,
                        createdDate: (0, utils_1.dateMinusDays)(160)
                    }
                ] }),
            EndlessArcStarted60DaysAgo: Object.assign(Object.assign({}, this.base), { id: this.getNextId(), bookId: seedBooks.BookForEndlessArc.id, neverEndingPrivate: true, liveDate: (0, utils_1.dateMinusDays)(60), userId: seedUsers.Me.id, arcTeamId: seedArcTeams.StanRegular.id, skipAudioBookCreation: true }),
            PendingPublishingWithReleaseDateInFutureArc: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.PendingPublishingWithReleaseDateInFuture)), { liveDate: (0, utils_1.dateAddDays)(-10), stopDownloadDate: (0, utils_1.dateAddDays)(2), dueDate: (0, utils_1.dateAddDays)(6), skipAudioBookCreation: true, userClaims: [
                    {
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(2), rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                        }
                    }
                ] }),
            PendingPublishingWithReleaseDateInFutureButHasPrintAsinArc: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.PendingPublishingWithReleaseDateInFutureButHasPrintAsin)), { liveDate: (0, utils_1.dateAddDays)(-10), stopDownloadDate: (0, utils_1.dateAddDays)(2), dueDate: (0, utils_1.dateAddDays)(6), skipAudioBookCreation: true, userClaims: [
                    {
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(2), rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                        }
                    }
                ] }),
            PendingPublishingWithReleaseDateInFutureWithOptionalSitesArc: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.PendingPublishingWithReleaseDateInFutureWithOptionalSites)), { liveDate: (0, utils_1.dateAddDays)(-10), stopDownloadDate: (0, utils_1.dateAddDays)(2), dueDate: (0, utils_1.dateAddDays)(6), optionalReviewFlags: FLAGS.GOOD_READS | FLAGS.BOOK_BUB, skipAudioBookCreation: true, userClaims: [
                    {
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(2), rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                        }
                    }
                ] }),
            PendingPublishingWithReleaseDateInFutureWithRequiredSitesArc: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.PendingPublishingWithReleaseDateInFutureWithRequiredSites)), { liveDate: (0, utils_1.dateAddDays)(-10), stopDownloadDate: (0, utils_1.dateAddDays)(2), dueDate: (0, utils_1.dateAddDays)(6), reviewFlags: FLAGS.AMAZON | FLAGS.GOOD_READS | FLAGS.BOOK_BUB, requiredReviewCount: 3, skipAudioBookCreation: true, userClaims: [
                    {
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(2), rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                        }
                    }
                ] }),
            PendingPublishingWithReleaseDateInPastAndHasPrintAsinArc: Object.assign(Object.assign({}, this.genericArc(this.getNextId(), seedBooks.PendingPublishingWithReleaseDateInPastAndHasPrintAsin)), { liveDate: (0, utils_1.dateAddDays)(-10), stopDownloadDate: (0, utils_1.dateAddDays)(0), dueDate: (0, utils_1.dateAddDays)(2), reviewFlags: FLAGS.AMAZON, requiredReviewCount: 1, skipAudioBookCreation: true, userClaims: [
                    {
                        userId: seedUsers.YourArcs.id,
                        createdDate: (0, utils_1.dateMinusDays)(7),
                        review: {
                            dto: Object.assign(Object.assign({}, this.baseReviewDto), { createdDate: (0, utils_1.dateMinusDays)(2), rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
                        }
                    }
                ] })
        };
        return Object.assign(Object.assign({}, testData), { RandomArcsWithClaims: this.generateRandomData() });
    }
    genericApprovedReview() {
        return {
            dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback' }),
            sites: [
                Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE })
            ]
        };
    }
    genericArc(id, book, team) {
        if (!team)
            team = seedArcTeams.StanRegular;
        return Object.assign(Object.assign({}, this.base), { id: id, bookId: book.id, userId: team.userId, arcTeamId: team.id, specialInformation: book.title + ' - ARC', reviewFlags: FLAGS.AMAZON, requiredReviewCount: 1, liveDate: (0, utils_1.dateMinusDays)(21), neverEndingPrivate: false, stopDownloadDate: (0, utils_1.dateAddDays)(21), dueDate: (0, utils_1.dateAddDays)(28), discoverableType: module_constants_1.bsConstants.ARCS.DISCOVERABLE_TYPE.PUBLIC });
    }
    getFakeSeed(id) {
        const seedArc = Object.assign(Object.assign({}, this.genericArc(id, seedBooks.BookForRandomArcs, seedArcTeams.TeamForRandomArcs)), { 
            // make sure these arcs don't affect other tests
            discoverableType: module_constants_1.bsConstants.ARCS.DISCOVERABLE_TYPE.TEAM_ONLY, skipAudioBookCreation: true });
        seedArc.userClaims = [];
        if (this.processedFakeArcs <= 3) {
            seedArc.userClaims.push({
                userId: seedUsers.ClaimNonMemberWeeklyLimitReached.id,
                createdDate: (0, utils_1.dateMinusDays)(4),
                skipEligibleCheck: true
            });
        }
        if (this.processedFakeArcs <= 30) {
            seedArc.userClaims.push({
                userId: seedUsers.ClaimNonMemberWeeklyLimitReachedValued.id,
                createdDate: (0, utils_1.dateMinusDays)(5),
                review: this.genericApprovedReview(),
                skipEligibleCheck: true
            }, {
                // delayed review on other claim is required, so we can check the bug is fixed
                userId: seedUsers.TopReviewerApplicationRejected.id,
                createdDate: (0, utils_1.dateMinusDays)(9),
                skipEligibleCheck: true,
                review: {
                    dto: Object.assign(Object.assign({}, this.baseReviewDto), { rating: 5, review: 'A 5 star review: ' + this.faker.lorem.paragraph().substring(0, 150), privateFeedback: 'Some private feedback top' }),
                    sites: [
                        Object.assign(Object.assign({}, this.baseSiteDto), { site: FLAGS.AMAZON, status: module_constants_1.bsConstants.ARCS.REVIEWS.SITE.STATUS.COMPLETE })
                    ]
                }
            });
        }
        this.processedFakeArcs += 1;
        return seedArc;
    }
}
exports.ArcSeeder = ArcSeeder;
