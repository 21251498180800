"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserNotificationDto = void 0;
const graphql_1 = require("@nestjs/graphql");
const arc_1 = require("../arc");
const model_dto_1 = require("./model.dto");
const base_1 = require("../base");
const book_1 = require("../book");
const graphql_2 = require("@nestjs/graphql");
const arc_team_1 = require("../arc-team");
let UserNotificationDto = class UserNotificationDto extends base_1.BaseModelDto {
};
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], UserNotificationDto.prototype, "status", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Date)
], UserNotificationDto.prototype, "statusDate", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], UserNotificationDto.prototype, "state", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], UserNotificationDto.prototype, "statusMessage", void 0);
__decorate([
    (0, graphql_1.Field)(t => model_dto_1.NotificationDto),
    __metadata("design:type", model_dto_1.NotificationDto)
], UserNotificationDto.prototype, "notification", void 0);
__decorate([
    (0, graphql_1.Field)(t => book_1.BookDto, { nullable: true }),
    __metadata("design:type", book_1.BookDto)
], UserNotificationDto.prototype, "book", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UserNotificationDto.prototype, "nameOfInstigator", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], UserNotificationDto.prototype, "updatedDate", void 0);
__decorate([
    (0, graphql_1.Field)(t => arc_1.ArcDto, { nullable: true }),
    __metadata("design:type", arc_1.ArcDto)
], UserNotificationDto.prototype, "arc", void 0);
__decorate([
    (0, graphql_1.Field)(t => arc_1.ArcClaimDto, { nullable: true }),
    __metadata("design:type", arc_1.ArcClaimDto)
], UserNotificationDto.prototype, "arcClaim", void 0);
__decorate([
    (0, graphql_1.Field)(t => arc_1.ArcReviewDto, { nullable: true }),
    __metadata("design:type", arc_1.ArcReviewDto)
], UserNotificationDto.prototype, "arcReview", void 0);
__decorate([
    (0, graphql_1.Field)(t => arc_1.ArcReviewSiteDto, { nullable: true }),
    __metadata("design:type", arc_1.ArcReviewSiteDto)
], UserNotificationDto.prototype, "arcReviewSite", void 0);
__decorate([
    (0, graphql_1.Field)(t => arc_team_1.ArcTeamDto, { nullable: true }),
    __metadata("design:type", arc_team_1.ArcTeamDto)
], UserNotificationDto.prototype, "arcTeam", void 0);
__decorate([
    (0, graphql_1.Field)(t => arc_team_1.ArcTeamMemberDto, { nullable: true }),
    __metadata("design:type", arc_team_1.ArcTeamMemberDto)
], UserNotificationDto.prototype, "arcTeamMember", void 0);
__decorate([
    (0, graphql_1.Field)(t => [String]),
    __metadata("design:type", Array)
], UserNotificationDto.prototype, "params", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], UserNotificationDto.prototype, "parsedMessage", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], UserNotificationDto.prototype, "parsedDescription", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], UserNotificationDto.prototype, "to", void 0);
UserNotificationDto = __decorate([
    (0, graphql_2.ObjectType)()
], UserNotificationDto);
exports.UserNotificationDto = UserNotificationDto;
