"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PenNameSeeder = void 0;
const seeder_1 = require("../seeder");
const module_constants_1 = require("../../module.constants");
const seeder_constants_1 = require("../../seeder.constants");
const user_1 = require("../user");
const userSeeder = new user_1.UserSeeder();
const seedUsers = Object.assign(Object.assign({}, userSeeder.getLiveDataObject()), userSeeder.getTestDataObject());
class PenNameSeeder extends seeder_1.Seeder {
    constructor() {
        super(...arguments);
        this.type = 'penName';
        this.maxModelCount = 40;
        this.base = {
            categoryId: seeder_constants_1.SeedCategories.ScienceFiction.id,
            wordCount: 1,
            audience: 1,
            preview: 'A long time ago in a galaxy far far away ...',
            penNameType: module_constants_1.bsConstants.PEN_NAME.TYPE.PUBLISHING
        };
    }
    getLiveDataObject() {
        return {
            DeletedPenName: {
                id: 1,
                name: '[Deleted Author]',
                image: '',
                userId: seedUsers.DeletedUser.id
            }
        };
    }
    getTestDataObject() {
        if (process.env.BUILD_MODE === 'master') {
            return {};
        }
        const testData = {
            TimothyZahn: {
                id: this.getNextId(),
                name: 'Timothy Zahn',
                image: 'TimothyZahn.jpg',
                userId: seedUsers.Me.id,
                addFollowers: [seedUsers.Me.id, seedUsers.OldBsUser.id]
            },
            DrewKarpyshyn: {
                id: this.getNextId(),
                name: 'Drew Karpyshyn',
                image: 'DrewKarpyshyn.jpg',
                userId: seedUsers.Publisher.id,
                addFollowers: [seedUsers.Me.id, seedUsers.DarthVadar.id, seedUsers.Thrawn.id]
            },
            FollowingAuthor: {
                id: this.getNextId(),
                name: 'Following Author',
                image: 'test.png',
                userId: seedUsers.Me.id,
                addFollowers: [seedUsers.StanRizzo.id]
            },
            GeneralAuthor: {
                id: this.getNextId(),
                name: 'General Author',
                image: 'test.png',
                userId: seedUsers.Me.id,
                addFollowers: [seedUsers.StanRizzo.id, seedUsers.NotificationTopReviewer.id],
                facebookLink: 'https://www.facebook.com',
                bookbubLink: 'https://www.bookbub.com',
                twitterLink: 'https://www.twitter.com',
                instagramLink: 'https://www.instagram.com'
            },
            NotificationAuthor: {
                id: this.getNextId(),
                name: 'Notification Author',
                image: 'test.png',
                userId: seedUsers.NotificationAuthor.id,
                addFollowers: [seedUsers.NotificationReviewer.id],
                facebookLink: 'https://www.facebook.com',
                bookbubLink: 'https://www.bookbub.com',
                twitterLink: 'https://www.twitter.com',
                instagramLink: 'https://www.instagram.com'
            },
            GrowingAuthor: {
                id: this.getNextId(),
                name: 'Growing Author',
                image: 'test.png',
                userId: seedUsers.GrowingAuthor.id,
                facebookLink: 'https://www.facebook.com',
                bookbubLink: 'https://www.bookbub.com',
                twitterLink: 'https://www.twitter.com',
                instagramLink: 'https://www.instagram.com'
            },
            ProAuthor: {
                id: this.getNextId(),
                name: 'Pro Author',
                image: 'test.png',
                userId: seedUsers.ProAuthor.id,
                facebookLink: 'https://www.facebook.com',
                bookbubLink: 'https://www.bookbub.com',
                twitterLink: 'https://www.twitter.com',
                instagramLink: 'https://www.instagram.com'
            },
            PenNameForRandomArcs: {
                id: this.getNextId(),
                name: 'Pen name for random arcs',
                image: 'test.png',
                userId: seedUsers.UserForRandomArcs.id,
            },
            EbookAndAudioBook: {
                id: this.getNextId(),
                name: 'Pen name EbookAndAudioBook',
                image: 'test.png',
                userId: seedUsers.EbookAndAudioBook.id,
            },
            DueForTooLong: {
                id: this.getNextId(),
                name: 'Pen name DueForTooLong',
                image: 'test.png',
                userId: seedUsers.Me.id,
            }
        };
        return testData;
    }
}
exports.PenNameSeeder = PenNameSeeder;
