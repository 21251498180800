"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArcClaimGetArgs = void 0;
const graphql_1 = require("@nestjs/graphql");
const baseGet_args_1 = require("../../base/baseGet.args");
let ArcClaimGetArgs = class ArcClaimGetArgs extends baseGet_args_1.BaseGetArgs {
};
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimGetArgs.prototype, "userId", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimGetArgs.prototype, "arcId", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimGetArgs.prototype, "bookId", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimGetArgs.prototype, "status", void 0);
__decorate([
    (0, graphql_1.Field)(t => [graphql_1.Int], { nullable: true }),
    __metadata("design:type", Array)
], ArcClaimGetArgs.prototype, "statuses", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimGetArgs.prototype, "claimId", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcClaimGetArgs.prototype, "userSearchNeedle", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimGetArgs.prototype, "type", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimGetArgs.prototype, "reviewSiteStatus", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], ArcClaimGetArgs.prototype, "forAuthenticatedUser", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], ArcClaimGetArgs.prototype, "mustHaveReviews", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimGetArgs.prototype, "groupByStatus", void 0);
__decorate([
    (0, graphql_1.Field)(t => [graphql_1.Int], { nullable: true }),
    __metadata("design:type", Array)
], ArcClaimGetArgs.prototype, "wordCounts", void 0);
__decorate([
    (0, graphql_1.Field)(t => [graphql_1.Int], { nullable: true }),
    __metadata("design:type", Array)
], ArcClaimGetArgs.prototype, "audienceTypes", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimGetArgs.prototype, "categoryId", void 0);
__decorate([
    (0, graphql_1.Field)(t => String, { nullable: true }),
    __metadata("design:type", String)
], ArcClaimGetArgs.prototype, "sortBy", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], ArcClaimGetArgs.prototype, "isDue", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcClaimGetArgs.prototype, "countOnly", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimGetArgs.prototype, "arcTeamId", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimGetArgs.prototype, "authorPenNameId", void 0);
ArcClaimGetArgs = __decorate([
    (0, graphql_1.ArgsType)()
], ArcClaimGetArgs);
exports.ArcClaimGetArgs = ArcClaimGetArgs;
