"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookDto = void 0;
const graphql_1 = require("@nestjs/graphql");
const baseModel_dto_1 = require("../base/baseModel.dto");
const pen_name_1 = require("../pen-name");
const module_constants_1 = require("../../module.constants");
const category_1 = require("../category");
let BookDto = class BookDto extends baseModel_dto_1.BaseModelDto {
    constructor() {
        super(...arguments);
        this.penNameType = module_constants_1.bsConstants.PEN_NAME.TYPE.MY_BOOK;
    }
};
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "title", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "description", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "penName", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], BookDto.prototype, "authorPenNameId", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { defaultValue: module_constants_1.bsConstants.PEN_NAME.TYPE.MY_BOOK }),
    __metadata("design:type", Number)
], BookDto.prototype, "penNameType", void 0);
__decorate([
    (0, graphql_1.Field)(t => pen_name_1.PenNameDto, { nullable: true }),
    __metadata("design:type", pen_name_1.PenNameDto)
], BookDto.prototype, "authorPenName", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "bookCover", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], BookDto.prototype, "releaseDate", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "seriesName", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], BookDto.prototype, "seriesNumber", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], BookDto.prototype, "categoryId", void 0);
__decorate([
    (0, graphql_1.Field)(t => category_1.CategoryDto, { nullable: true }),
    __metadata("design:type", category_1.CategoryDto)
], BookDto.prototype, "category", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "keywords", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], BookDto.prototype, "wordCount", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], BookDto.prototype, "audience", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkAmazonAsin", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkAmazonAsinPrint", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkGoodreads", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkBookbub", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkBarnes", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkGoogle", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkKobo", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkSmashwords", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "smashwordsCouponCode", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkITunes", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkAudibleUs", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkAudibleUk", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkITunesAudio", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkBarnesAudio", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkGoogleAudio", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkGoodreadsAudio", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkKoboAudio", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "linkBookbubAudio", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Float, { nullable: true }),
    __metadata("design:type", Number)
], BookDto.prototype, "averageRating", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], BookDto.prototype, "reviewCount", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Boolean)
], BookDto.prototype, "isDraft", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], BookDto.prototype, "lastArcOn", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], BookDto.prototype, "newReviewCount", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], BookDto.prototype, "updatedReviewCount", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], BookDto.prototype, "importedId", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "pdfFile", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], BookDto.prototype, "pdfFileSizeBytes", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], BookDto.prototype, "pdfSystemGenerated", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], BookDto.prototype, "pdfFileDate", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "epubFile", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], BookDto.prototype, "epubFileSizeBytes", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "epubPreviewFile", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], BookDto.prototype, "epubSystemGenerated", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], BookDto.prototype, "epubFileDate", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "epubFileFriendlyName", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], BookDto.prototype, "pdfFileFriendlyName", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], BookDto.prototype, "awaitingBookFileFlag", void 0);
BookDto = __decorate([
    (0, graphql_1.ObjectType)()
], BookDto);
exports.BookDto = BookDto;
