"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArcTeamMemberDto = void 0;
const graphql_1 = require("@nestjs/graphql");
const model_dto_1 = require("../../user/model.dto");
const baseModel_dto_1 = require("../../base/baseModel.dto");
const model_dto_2 = require("../model.dto");
const user_1 = require("../../user");
let ArcTeamMemberDto = class ArcTeamMemberDto extends baseModel_dto_1.BaseModelDto {
    constructor() {
        super(...arguments);
        this.isDraft = false;
        this.activeArcCount = 0;
        this.reviewedArcCount = 0;
        this.cancelledArcCount = 0;
        this.pastDueArcCount = 0;
        this.averageRating = 0;
    }
};
__decorate([
    (0, graphql_1.Field)(t => Boolean, { defaultValue: false }),
    __metadata("design:type", Boolean)
], ArcTeamMemberDto.prototype, "isDraft", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], ArcTeamMemberDto.prototype, "status", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], ArcTeamMemberDto.prototype, "type", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", model_dto_1.UserDto)
], ArcTeamMemberDto.prototype, "user", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true, defaultValue: 0 }),
    __metadata("design:type", Number)
], ArcTeamMemberDto.prototype, "activeArcCount", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true, defaultValue: 0 }),
    __metadata("design:type", Number)
], ArcTeamMemberDto.prototype, "reviewedArcCount", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true, defaultValue: 0 }),
    __metadata("design:type", Number)
], ArcTeamMemberDto.prototype, "cancelledArcCount", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true, defaultValue: 0 }),
    __metadata("design:type", Number)
], ArcTeamMemberDto.prototype, "pastDueArcCount", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Float, { nullable: true, defaultValue: 0 }),
    __metadata("design:type", Number)
], ArcTeamMemberDto.prototype, "averageRating", void 0);
__decorate([
    (0, graphql_1.Field)(t => model_dto_2.ArcTeamDto, { nullable: true }),
    __metadata("design:type", model_dto_2.ArcTeamDto)
], ArcTeamMemberDto.prototype, "arcTeam", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], ArcTeamMemberDto.prototype, "arcTeamId", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], ArcTeamMemberDto.prototype, "updatedDate", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Number)
], ArcTeamMemberDto.prototype, "statusReasonType", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcTeamMemberDto.prototype, "statusReason", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], ArcTeamMemberDto.prototype, "reportedDate", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], ArcTeamMemberDto.prototype, "statusDate", void 0);
__decorate([
    (0, graphql_1.Field)(t => user_1.UserEmailDto, { nullable: true }),
    __metadata("design:type", user_1.UserEmailDto)
], ArcTeamMemberDto.prototype, "userEmail", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcTeamMemberDto.prototype, "previousStatus", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcTeamMemberDto.prototype, "previousType", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcTeamMemberDto.prototype, "reviewerStatus", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcTeamMemberDto.prototype, "reviewerLogType", void 0);
ArcTeamMemberDto = __decorate([
    (0, graphql_1.ObjectType)()
], ArcTeamMemberDto);
exports.ArcTeamMemberDto = ArcTeamMemberDto;
