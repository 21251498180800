"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const baseAuthorCRUDModels = [
    'authors', 'view-author',
    'message',
    'teams', 'view-team',
    'arc-teams', 'view-arc-team',
    'arcs', 'view-arc', 'cancel-arc-claim', 'claim-arc', 'review-arc',
    'your-arcs',
    'books', 'view-book',
    'gamification', 'rank'
];
class ReviewerRole {
    constructor() {
        // CRUD operation permissions
        this.read = baseAuthorCRUDModels;
        this.arcDownloadsPerWeek = 3;
        this.totalClaimOverdueDayAllowance = 10;
        this.arcMaxDownloads = 10;
        this.requiredReviewsForTier = 0;
        this.requiredReviewRateForTier = 30;
    }
}
exports.default = ReviewerRole;
