export const WebsitePaths = {
  home: '/',
  readers: '/readers',
  pricing: '/pricing',
  about: '/about-us',
  login: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/login' : '/publisher/login',
  signUp: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/sign-up' : '/publisher/sign-up',
  privacyPolicy: '/privacy-policy',
  terms: '/terms-and-conditions',
  affiliateDisclosure: '/affiliate-disclosure',
  cookiePolicy: '/cookie-policy',
  reviewCopies: process.env.NODE_ENV === 'development'
    ? 'http://localhost:8081/reviewer/review-copies'
    : '/reviewer/review-copies',
  privacyPolicyAllProducts: 'privacy-policy-all-products',
  affiliateProgram: 'affiliate-program',
  affiliateTermsAndConditions: 'affiliate-terms-and-conditions',
  appleLink: 'https://apps.apple.com/gb/app/booksprout/id1590873280',
  googlePlayLink: 'https://play.google.com/store/apps/details?id=co.booksprout.reviewer',
  amazonLink: 'https://www.amazon.co.uk/Booksprout-Web-Services-Inc/dp/B0B1QKDW21'
}
