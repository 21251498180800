import { MutationTree } from 'vuex'

import {
  AUTH_CONSENT_FAILED,
  AUTH_CONSENT_NEEDED,
  AUTH_EXPIRED,
  AUTH_LOAD,
  AUTH_LOGOUT,
  AUTH_SUCCESS,
  SET_CSRF_TOKEN,
  SET_USER
} from '../../actions/auth'
import { AuthState } from './types'
import { resetAuthState } from './state'
import { LoginResultDto, UserDto } from 'booksprout'
import { validInt } from '../../../../../../../common/src/utils'
import { Platform } from 'quasar'

export const mutations: MutationTree<AuthState> = {
  [AUTH_LOAD] (state: AuthState, data: LoginResultDto) {
    state.user = data.user
    state.token = data.token
    if (validInt(data.mimicUser?.id)) {
      state.mimicUser = data.mimicUser
    }
  },
  [AUTH_SUCCESS] (state: AuthState, loginResult: LoginResultDto) {
    // If we're on IOS we're using localstorage so
    if (Platform.is.ios) {
      state.token = loginResult.token
    } else {
      state.token = {
        data: '',
        expires: loginResult.token.expires
      }
    }

    state.user = loginResult.user

    if (validInt(loginResult.mimicUser?.id)) {
      state.mimicUser = loginResult.mimicUser
    } else {
      // We've got an auth with no mimic, make sure we're don't have an existing one set.
      state.mimicUser = void 0
    }
  },
  [AUTH_LOGOUT] (state: AuthState) {
    Object.assign(state, resetAuthState())
  },
  [AUTH_CONSENT_FAILED] (state: AuthState, data) {
    if (data === null) {
      Object.assign(state, resetAuthState())
    } else {
      state.termsConsentFailed = data.loginResult.termsConsentFailed
      // We might want access to the user whilst telling them we need their consent so store this info (no login token though)
      state.user = data.loginResult.user

      state.termsConsentData.loginResult = data.loginResult
    }
  },
  [AUTH_CONSENT_NEEDED] (state: AuthState, data) {
    if (data === null) {
      Object.assign(state, resetAuthState())
    } else {
      state.termsConsentNeeded = data.loginResult.termsConsentNeeded

      // We might want access to the user whilst telling them we need their consent so store this info (no login token though)
      state.user = data.loginResult.user

      state.termsConsentData.loginResult = data.loginResult
    }
  },
  [AUTH_EXPIRED] (state: AuthState, hasExpired: boolean) {
    state.authExpired = hasExpired
  },
  [SET_USER] (state: AuthState, user: UserDto) {
    if (state.user !== void 0) {
      state.user = user
    }
  },
  [SET_CSRF_TOKEN] (state: AuthState, token: string) {
    state.csrfToken = token
  }
}
