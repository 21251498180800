"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeedNotifications = exports.SeedCategories = void 0;
const module_constants_1 = require("./module.constants");
const utils_1 = require("./utils");
exports.SeedCategories = {
    ActionAndAdventure: {
        id: 1,
        name: 'Action & Adventure'
    },
    Erotica: {
        id: 2,
        name: 'Erotica'
    },
    Fantasy: {
        id: 3,
        name: 'Fantasy'
    },
    Horror: {
        id: 4,
        name: 'Horror'
    },
    Mystery: {
        id: 5,
        name: 'Mystery'
    },
    Nonfiction: {
        id: 6,
        name: 'Nonfiction'
    },
    OtherFiction: {
        id: 7,
        name: 'Other Fiction'
    },
    Romance: {
        id: 8,
        name: 'Romance'
    },
    ScienceFiction: {
        id: 9,
        name: 'Science Fiction'
    },
    Suspense: {
        id: 10,
        name: 'Suspense'
    },
    TeenAndYoungReaders: {
        id: 11,
        name: 'Teen and Young Readers'
    },
    Thriller: {
        id: 12,
        name: 'Thriller'
    },
    NoCategory: {
        id: 13,
        name: 'No Category'
    }
};
const baseNotification = {
    active: true,
    typeFlags: module_constants_1.bsConstants.NOTIFICATIONS.FLAGS.EMAIL | module_constants_1.bsConstants.NOTIFICATIONS.FLAGS.APP | module_constants_1.bsConstants.NOTIFICATIONS.FLAGS.MOBILE_APP
};
exports.SeedNotifications = {
    NewArc: Object.assign(Object.assign({}, baseNotification), { id: 1, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.NEW_ARC.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.NEW_ARC.TEMPLATE_ID, permissionPreferenceKey: 'alertNewArc' }),
    TimeToPublish: Object.assign(Object.assign({}, baseNotification), { id: 2, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.TIME_TO_PUBLISH.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.TIME_TO_PUBLISH.TEMPLATE_ID, permissionPreferenceKey: 'alertTimeToPublish' }),
    ReviewsDueSoon: Object.assign(Object.assign({}, baseNotification), { id: 3, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.REVIEW_DUE_SOON.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.REVIEW_DUE_SOON.TEMPLATE_ID, permissionPreferenceKey: 'alertReviewDueIn2Days' }),
    readyToReviewEverywhere: Object.assign(Object.assign({}, baseNotification), { id: 4, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.READY_TO_REVIEW.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.READY_TO_REVIEW.TEMPLATE_ID, permissionPreferenceKey: 'alertReadyToReview' }),
    finalFollowUp: Object.assign(Object.assign({}, baseNotification), { id: 5, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.FINAL_FOLLOW_UP.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.FINAL_FOLLOW_UP.TEMPLATE_ID, permissionPreferenceKey: 'alertFinalFollowUp' }),
    delayExpiring: Object.assign(Object.assign({}, baseNotification), { id: 6, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.DELAY_EXPIRING.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.DELAY_EXPIRING.TEMPLATE_ID, permissionPreferenceKey: 'alertDelayExpiring' }),
    delayExpired: Object.assign(Object.assign({}, baseNotification), { id: 7, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.DELAY_EXPIRED.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.DELAY_EXPIRED.TEMPLATE_ID, permissionPreferenceKey: 'alertDelayExpired' }),
    newArcParticipant: Object.assign(Object.assign({}, baseNotification), { id: 8, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.NEW_ARC_PARTICIPANT.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.NEW_ARC_PARTICIPANT.TEMPLATE_ID, permissionPreferenceKey: 'newArcParticipant' }),
    newReview: Object.assign(Object.assign({}, baseNotification), { id: 9, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.NEW_REVIEW.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.NEW_REVIEW.TEMPLATE_ID, permissionPreferenceKey: 'newReview' }),
    arcCancellation: Object.assign(Object.assign({}, baseNotification), { id: 10, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.ARC_CANCELLATION.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.ARC_CANCELLATION.TEMPLATE_ID, permissionPreferenceKey: 'arcCancellation' }),
    reviewRejected: Object.assign(Object.assign({}, baseNotification), { id: 11, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.REVIEW_REJECTED.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.REVIEW_REJECTED.TEMPLATE_ID, permissionPreferenceKey: 'reviewRejected' }),
    newExternalReview: Object.assign(Object.assign({}, baseNotification), { id: 12, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.NEW_EXTERNAL_REVIEW.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.NEW_EXTERNAL_REVIEW.TEMPLATE_ID, permissionPreferenceKey: 'newExternalReview' }),
    freeText: Object.assign(Object.assign({}, baseNotification), { id: 13, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.FREE_TEXT.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.FREE_TEXT.TEMPLATE_ID, permissionPreferenceKey: 'freeText' }),
    invitedToTeam: Object.assign(Object.assign({}, baseNotification), { id: 14, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.INVITED_TO_TEAM.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.INVITED_TO_TEAM.TEMPLATE_ID, permissionPreferenceKey: 'invitedToTeam' }),
    arcCannotActivate: Object.assign(Object.assign({}, baseNotification), { id: 15, code: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.ARC_CANNOT_ACTIVATE.CODE, lastQueuedDate: (0, utils_1.newDate)(0), templateId: module_constants_1.bsConstants.NOTIFICATIONS.NOTIFIERS.ARC_CANNOT_ACTIVATE.TEMPLATE_ID, permissionPreferenceKey: 'arcCannotActivate' })
};
