"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidReviewLink = exports.getArcSitesFromFlags = exports.getArcSites = exports.calculateArcDueDate = void 0;
const module_constants_1 = require("../../module.constants");
const utils_1 = require("../../utils");
const calculateArcDueDate = (dto) => {
    if (dto.neverEndingPrivate) {
        return void 0; // No due date
    }
    else {
        return (0, utils_1.endOfDay)(dto.dueDate || (0, utils_1.newDate)(0));
    }
};
exports.calculateArcDueDate = calculateArcDueDate;
const getArcSites = (forceAdd, requiredCallback) => {
    // Some regexes to setup validation for use on input if required.
    const validationRegexes = {
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.AMAZON]: /^[A-Z0-9]{10}$/gm,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.GOOGLE]: /^((http|https):\/\/)?(www\.)?(play\.google)\.com/gm,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.GOOD_READS]: /^((http|https):\/\/)?(www\.)?(goodreads)\.com/gm,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.KOBO]: /^((http|https):\/\/)?(www\.)?((store\.)?kobo)\.com/gm,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.BOOK_BUB]: /^((http|https):\/\/)?(www\.)?(bookbub)\.com/gm,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.ITUNES]: /^((http|https):\/\/)?(www\.)?((itunes|books)?\.apple)\.com/gm,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.BARNES]: /^((http|https):\/\/)?(www\.)?(barnes|barnesandnoble)\.com/gm,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.SMASH_WORDS]: /^((http|https):\/\/)?(www\.)?(smashwords)\.com/gm,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.AUDIBLE_US]: void 0,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.AUDIBLE_UK]: void 0,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_GOOGLE]: /^((http|https):\/\/)?(www\.)?(play\.google)\.com/gm,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_GOODREADS]: /^((http|https):\/\/)?(www\.)?(goodreads)\.com/gm,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_KOBO]: /^((http|https):\/\/)?(www\.)?((store\.)?kobo)\.com/gm,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_BOOK_BUB]: /^((http|https):\/\/)?(www\.)?(bookbub)\.com/gm,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_ITUNES]: /^((http|https):\/\/)?(www\.)?((itunes|books)?\.apple)\.com/gm,
        [module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_BARNES]: /^((http|https):\/\/)?(www\.)?(barnes|barnesandnoble)\.com/gm,
    };
    const result = {
        requiredCount: 0,
        sites: []
    };
    for (const siteKey in module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS) {
        const site = module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS[siteKey];
        // Is this the "none" site; skip.
        if (site === 0 || site === module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_AUTHORS_DIRECT) {
            continue;
        }
        // Get the field name (property against the book) so we know which one to update
        const fieldName = module_constants_1.bsConstants.ARCS.REVIEWS.FLAG_BOOK_KEYS[siteKey];
        // is it required?
        const required = typeof requiredCallback === 'function' ? requiredCallback(fieldName, site) : true;
        // Show the required ones or ALL if the author has selected the show all button
        if (required || forceAdd) {
            const validationRegex = validationRegexes[site];
            const rules = [];
            if (validationRegex !== void 0) {
                if (site === module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.AMAZON) {
                    rules.push((val) => ((val && val.match(validationRegex)) || !val) || 'ASINs must be 10 characters in length.');
                }
                else {
                    rules.push((val) => ((val && val.match(validationRegex)) || !val) || 'Invalid Link for this site.');
                }
            }
            result.sites.push({
                description: module_constants_1.bsConstants.ARCS.REVIEWS.FLAG_DESCRIPTIONS[site.toString()],
                fieldName,
                site,
                rules
            });
            if (forceAdd && site === module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.AMAZON) {
                result.sites.push({
                    description: 'Amazon (Print)',
                    fieldName: 'linkAmazonAsinPrint',
                    site: module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.AMAZON,
                    rules
                });
            }
            if (required) {
                result.requiredCount++;
            }
        }
    }
    result.sites = result.sites.sort((a, b) => {
        return a.fieldName === b.fieldName
            ? 0
            : a.fieldName < b.fieldName
                ? -1
                : 1;
    });
    return result;
};
exports.getArcSites = getArcSites;
const getArcSitesFromFlags = (flags, excludeFlags) => {
    const results = [];
    for (const siteKey in module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS) {
        const site = module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS[siteKey];
        // Is this the "none" site?
        // Are we excluding this site?
        // Skip
        if (site === 0 || (excludeFlags && (excludeFlags & site) === site)) {
            continue;
        }
        if ((site & flags) === site) {
            results.push({
                description: module_constants_1.bsConstants.ARCS.REVIEWS.FLAG_DESCRIPTIONS[site.toString()],
                fieldName: module_constants_1.bsConstants.ARCS.REVIEWS.FLAG_BOOK_KEYS[siteKey],
                site,
                rules: []
            });
        }
    }
    return results;
};
exports.getArcSitesFromFlags = getArcSitesFromFlags;
/**
 * See: https://app.nuclino.com/Booksprout/Booksprout-v2/Reviewing-on-Amazon-6ef5174d-ce9d-493a-964d-3f5999d897e4
 * for rules for amazon
 * @param site
 * @param url
 */
const isValidReviewLink = (site, url) => {
    const sites = __rest(module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS, []);
    let regex;
    switch (site) {
        case sites.AMAZON:
            // TODO: AG - Restrict to amazon domain as well.
            regex = new RegExp(/\/((review(s?)\/|customer\-review(s?)\/|product-review(s?)\/)*(R[a-zA-Z0-9]{10,14}))|((u=http)(.*)(review(s?)%2F|customer\-review(s?)%2F|product-review(s?)%2F)*(R[a-zA-Z0-9]{10,14}))/gi);
            break;
        case sites.BOOK_BUB:
            regex = new RegExp(/^((http|https):\/\/)?(((www\.)?bookbub\.com\/reviews\/\d+))/gi);
            break;
        case sites.GOOD_READS:
            regex = new RegExp(/^((http|https):\/\/)?(((www\.)?goodreads\.com\/review\/show\/\d+))/gi);
            break;
        default:
            return true;
    }
    return regex.test(url);
};
exports.isValidReviewLink = isValidReviewLink;
