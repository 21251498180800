"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageSeeder = void 0;
const seeder_1 = require("../seeder");
const module_constants_1 = require("../../module.constants");
const user_1 = require("../user");
const arc_1 = require("../arc");
class MessageSeeder extends seeder_1.Seeder {
    constructor() {
        super();
        this.type = 'messageThread';
        this.maxModelCount = 3;
        this.base = {
            state: module_constants_1.bsConstants.MESSAGES.STATE.NEW,
            posts: []
        };
        this.seedUsers = new user_1.UserSeeder().getTestDataObject();
        this.seedArcs = new arc_1.ArcSeeder().getTestDataObject();
    }
    getLiveDataObject() {
        return undefined;
    }
    getTestDataObject() {
        if (process.env.BUILD_MODE === 'master') {
            return {};
        }
        return {
            AllegianceArc: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { arcId: this.seedArcs.Allegiance_NeverEnding10Day.id, reviewerUserId: this.seedUsers.StanRizzo.id, userId: this.seedArcs.Allegiance_NeverEnding10Day.userId, posts: [
                    {
                        message: this.faker.lorem.paragraph().substring(0, 150),
                        userId: this.seedUsers.StanRizzo.id,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW
                    },
                    {
                        message: this.faker.lorem.paragraph().substring(0, 150),
                        userId: this.seedUsers.StanRizzo.id,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW
                    },
                    {
                        message: this.faker.lorem.paragraph().substring(0, 150),
                        userId: this.seedArcs.Allegiance_NeverEnding10Day.userId,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW,
                        fromArcTeamId: this.seedArcs.Allegiance_NeverEnding10Day.arcTeamId
                    },
                    {
                        message: this.faker.lorem.paragraph().substring(0, 150),
                        userId: this.seedUsers.StanRizzo.id,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW
                    },
                    {
                        message: 'Last message to show',
                        userId: this.seedArcs.Allegiance_NeverEnding10Day.userId,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW,
                        fromArcTeamId: this.seedArcs.Allegiance_NeverEnding10Day.arcTeamId
                    }
                ] }),
            DarkForceRising: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { arcId: this.seedArcs.DarkForceRising.id, reviewerUserId: this.seedUsers.StanRizzo.id, userId: this.seedArcs.DarkForceRising.userId, posts: [
                    {
                        message: this.faker.lorem.paragraph().substring(0, 150),
                        userId: this.seedUsers.StanRizzo.id,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW
                    },
                    {
                        message: this.faker.lorem.paragraph().substring(0, 150),
                        userId: this.seedUsers.StanRizzo.id,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW
                    },
                    {
                        message: this.faker.lorem.paragraph().substring(0, 150),
                        userId: this.seedArcs.DarkForceRising.userId,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW,
                        fromArcTeamId: this.seedArcs.DarkForceRising.arcTeamId
                    },
                    {
                        message: this.faker.lorem.paragraph().substring(0, 150),
                        userId: this.seedUsers.StanRizzo.id,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW
                    },
                    {
                        message: this.faker.lorem.paragraph().substring(0, 150),
                        userId: this.seedArcs.DarkForceRising.userId,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW,
                        fromArcTeamId: this.seedArcs.DarkForceRising.arcTeamId
                    }
                ] }),
            ChoicesOfOne: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { arcId: this.seedArcs.ChoicesOfOne.id, reviewerUserId: this.seedUsers.StanRizzo.id, userId: this.seedArcs.ChoicesOfOne.userId, posts: [
                    {
                        message: 'Hey!',
                        userId: this.seedUsers.StanRizzo.id,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW
                    }
                ] }),
            ChoicesOfOneBlocked: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { arcId: this.seedArcs.ChoicesOfOne.id, reviewerUserId: this.seedUsers.LukeSkywalker.id, userId: this.seedArcs.ChoicesOfOne.userId, posts: [
                    {
                        message: 'I am blocked',
                        userId: this.seedUsers.LukeSkywalker.id,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW
                    }
                ] }),
            Deceived: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { arcId: this.seedArcs.Deceived.id, reviewerUserId: this.seedUsers.StanRizzo.id, userId: this.seedArcs.Deceived.userId, posts: [
                    {
                        message: 'Can only reply as ARC Team - not pen name.',
                        userId: this.seedUsers.StanRizzo.id,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW
                    }
                ] }),
            TooManyCancellationsReported: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { arcId: this.seedArcs.TooManyCancellationsReported.id, reviewerUserId: this.seedUsers.BadBoy.id, userId: this.seedArcs.TooManyCancellationsReported.userId, posts: [
                    {
                        message: 'I am sooo bad, report me!',
                        userId: this.seedUsers.BadBoy.id,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.FLAGGED
                    }
                ] }),
            MessageFromShadowBannedUser: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { arcId: this.seedArcs.ChoicesOfOne.id, reviewerUserId: this.seedUsers.ShadowBan.id, userId: this.seedArcs.ChoicesOfOne.userId, posts: [
                    {
                        message: 'Message from shadow banned user, SHOULD NOT BE VISIBLE!',
                        userId: this.seedUsers.ShadowBan.id,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW
                    }
                ] }),
            MessageFromStanOnHasReviewsArc: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { arcId: this.seedArcs.HasReviews.id, reviewerUserId: this.seedUsers.StanRizzo.id, userId: this.seedArcs.HasReviews.userId, posts: [
                    {
                        message: 'Should show review details from Stan',
                        userId: this.seedUsers.StanRizzo.id,
                        state: module_constants_1.bsConstants.MESSAGES.STATE.NEW
                    }
                ] }),
            // Random: this.generateRandomData()
        };
    }
    getFakeSeed(id) {
        const arcToUse = this.getSeedWithId(this.seedArcs, id);
        if (arcToUse !== void 0 && arcToUse.userClaims !== void 0) {
            const recipientUserId = arcToUse.userClaims[0].userId;
            const posts = [];
            // Generate 5 random messages between the user / author
            for (let i = 0; i < 5; i++) {
                const zeroOrOne = Math.round(Math.random());
                posts.push({
                    message: this.faker.lorem.paragraph().substring(0, 150),
                    userId: zeroOrOne === 0 ? arcToUse === null || arcToUse === void 0 ? void 0 : arcToUse.userId : recipientUserId,
                    state: module_constants_1.bsConstants.MESSAGES.STATE.NEW,
                    fromArcTeamId: zeroOrOne === 0 ? arcToUse === null || arcToUse === void 0 ? void 0 : arcToUse.arcTeamId : void 0
                });
            }
            return Object.assign(Object.assign({ id }, this.base), { arcId: arcToUse.id, reviewerUserId: recipientUserId, userId: arcToUse.userId, posts });
        }
        return void 0;
    }
}
exports.MessageSeeder = MessageSeeder;
