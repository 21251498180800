"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AuthorRole_1 = require("./AuthorRole");
class ProAuthor extends AuthorRole_1.AuthorRole {
    constructor() {
        super(...arguments);
        this.maxParticipantsPerArc = 50;
        this.maxRunningArcs = 3;
        this.canCreatePrivateArcs = true;
        this.canHaveVIPTeamMembers = true;
        this.canCreateAudioBookArcs = true;
        this.canScheduleArcs = true;
        this.canLimitReviewersToAllOnSeries = true;
        this.canIdentifyPiracy = true;
        this.canRequireReviewOnPreviousBooksInSeries = true;
        this.canShowSocialLinksOnAuthorPages = true;
    }
}
exports.default = ProAuthor;
