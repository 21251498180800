"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionService = void 0;
const booksprout_1 = require("booksprout");
class PermissionService {
    get isOnPaidPlan() {
        return this.user &&
            this.user.authorRole &&
            this.user.authorRole !== booksprout_1.bsConstants.USER.ROLES.AUTHOR.FREE;
    }
    get isDemoFeature() {
        var _a;
        return ['sandbox'].includes(process.env.BUILD_MODE + '')
            && ![
                'me@allangaunt.dev',
                'author.ginnie@gmail.com',
                'tomasjakelis@gmail.com'
            ].includes(((_a = this.user) === null || _a === void 0 ? void 0 : _a.emailAddress) || '');
    }
    get isPublisher() {
        return this.roleName === booksprout_1.bsConstants.USER.ROLES.AUTHOR.PUBLISHER;
    }
    get roleName() {
        if (!this.user)
            return '';
        // console.log(this.user.authorRole, this.user.reviewerRole)
        return this.user.authorRole ? this.user.authorRole : this.user.reviewerRole;
    }
    configure(user, force) {
        // Just a precaution in case it's gets configured more than once.
        // No point doing more work - just return the instance.
        if (this.permissions !== void 0 && !force) {
            return this;
        }
        if (user !== void 0) {
            this.user = user;
            this.permissions = (0, booksprout_1.getPermissions)(this.user.reviewerRole, this.user.authorRole);
        }
        return this;
    }
    create(resource, roleType) {
        return this.can('create', resource, roleType);
    }
    read(resource, roleType) {
        return this.can('read', resource, roleType);
    }
    update(resource, roleType) {
        return this.can('update', resource, roleType);
    }
    delete(resource, roleType) {
        return this.can('delete', resource, roleType);
    }
    /**
     * Does a user have permission to do something
     * This code purposely only checks permissions against one of the roles. The reason being is they might have read permissions
     * for ARC on the reviewer side but not on the author side so we need to tell it which permission we're checking for when
     * calling the method.
     * @param action - The permission key we're checking the user has
     * @param resource - The value we're expecting it to have for to be true
     * @param roleType - The role of user (admin, author, reviewer etc)
     */
    can(action, resource, roleType) {
        if (!this.user)
            return false;
        if (this.user.isAdmin)
            return true;
        if (roleType === booksprout_1.bsConstants.USER.ROLES.TYPE.AUTHOR) {
            // Permission doesn't exist in the reviewer ones, try in the author (if they exist)
            if (this.permissions.authorPerms === void 0)
                return false;
            return this.permissions.authorPerms[action].includes(resource);
        }
        else if (roleType === booksprout_1.bsConstants.USER.ROLES.TYPE.REVIEWER) {
            const result = this.permissions.reviewerPerms[action];
            if (result)
                return result.includes(resource);
        }
        else if (roleType === booksprout_1.bsConstants.USER.ROLES.TYPE.ADMIN) {
            const result = this.permissions.authorPerms[action];
            if (result)
                return result.includes(resource);
        }
        return false;
    }
    /**
     * Is a user within a limit defined by their permissions?
     * @param action - The permission key we're checking the user has
     * @param currentCount - The current count of this key they have i.e current ArcCount
     */
    withinLimit(action, currentCount) {
        const reviewerActionLimit = this.permissions.reviewerPerms[action];
        if (reviewerActionLimit)
            return currentCount < reviewerActionLimit || reviewerActionLimit === -1;
        if (this.permissions.authorPerms === void 0)
            return false;
        const authorPermissionCount = (this.permissions.authorPerms[action] || 0);
        return currentCount < authorPermissionCount || authorPermissionCount === -1;
    }
    /**
     * Permissions work in a tiered fashion. This allows us to check if a user role is "good enough" to see a required role.
     * @param authorRole
     * @param reviewerRole
     */
    hasTieredPermission(authorRole, reviewerRole) {
        // console.log('Has tiered: ', authorRole, reviewerRole)
        if (authorRole) {
            const authorTiers = {
                [booksprout_1.bsConstants.USER.ROLES.AUTHOR.FREE]: [
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.FREE
                ],
                [booksprout_1.bsConstants.USER.ROLES.AUTHOR.GROWING]: [
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.FREE,
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.GROWING
                ],
                [booksprout_1.bsConstants.USER.ROLES.AUTHOR.PRO]: [
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.FREE,
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.GROWING,
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.PRO
                ],
                [booksprout_1.bsConstants.USER.ROLES.AUTHOR.BEST_SELLING]: [
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.FREE,
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.GROWING,
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.PRO,
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.BEST_SELLING
                ],
                [booksprout_1.bsConstants.USER.ROLES.AUTHOR.PUBLISHER]: [
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.FREE,
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.GROWING,
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.PRO,
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.BEST_SELLING,
                    booksprout_1.bsConstants.USER.ROLES.AUTHOR.PUBLISHER
                ]
            };
            const roles = authorTiers[this.user.authorRole || ''];
            if (roles === void 0) {
                return false;
            }
            return roles.includes(authorRole);
        }
        else if (reviewerRole) {
            const reviewerTiers = {
                [booksprout_1.bsConstants.USER.ROLES.REVIEWER.NEW]: [
                    booksprout_1.bsConstants.USER.ROLES.REVIEWER.NEW
                ],
                [booksprout_1.bsConstants.USER.ROLES.REVIEWER.VALUED]: [
                    booksprout_1.bsConstants.USER.ROLES.REVIEWER.NEW,
                    booksprout_1.bsConstants.USER.ROLES.REVIEWER.VALUED
                ],
                [booksprout_1.bsConstants.USER.ROLES.REVIEWER.TOP]: [
                    booksprout_1.bsConstants.USER.ROLES.REVIEWER.NEW,
                    booksprout_1.bsConstants.USER.ROLES.REVIEWER.VALUED,
                    booksprout_1.bsConstants.USER.ROLES.REVIEWER.TOP
                ]
            };
            const roles = reviewerTiers[this.user.reviewerRole || ''];
            if (roles === void 0) {
                return false;
            }
            return roles.includes(reviewerRole);
        }
        return false;
    }
}
exports.PermissionService = PermissionService;
