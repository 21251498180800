"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateEpubPreviewDto = void 0;
const graphql_1 = require("@nestjs/graphql");
let GenerateEpubPreviewDto = class GenerateEpubPreviewDto {
};
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], GenerateEpubPreviewDto.prototype, "epubFileName", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], GenerateEpubPreviewDto.prototype, "bookId", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], GenerateEpubPreviewDto.prototype, "cutAtCfi", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], GenerateEpubPreviewDto.prototype, "lastPageBodyHtml", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], GenerateEpubPreviewDto.prototype, "lastPageUrl", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Boolean)
], GenerateEpubPreviewDto.prototype, "convertFromLocalFile", void 0);
GenerateEpubPreviewDto = __decorate([
    (0, graphql_1.ArgsType)()
], GenerateEpubPreviewDto);
exports.GenerateEpubPreviewDto = GenerateEpubPreviewDto;
