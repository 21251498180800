"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationDto = void 0;
const graphql_1 = require("@nestjs/graphql");
const baseModelWithoutUser_dto_1 = require("../base/baseModelWithoutUser.dto");
const arc_1 = require("../arc");
const graphql_2 = require("@nestjs/graphql");
let NotificationDto = class NotificationDto extends baseModelWithoutUser_dto_1.BaseModelWithoutUserDto {
};
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], NotificationDto.prototype, "code", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", String)
], NotificationDto.prototype, "permissionPreferenceKey", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], NotificationDto.prototype, "typeFlags", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Boolean)
], NotificationDto.prototype, "active", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Date)
], NotificationDto.prototype, "lastQueuedDate", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], NotificationDto.prototype, "userAwaitingNotificationCount", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], NotificationDto.prototype, "userNotificationCount", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], NotificationDto.prototype, "status", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], NotificationDto.prototype, "statusDate", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], NotificationDto.prototype, "templateId", void 0);
__decorate([
    (0, graphql_1.Field)(t => arc_1.ArcDto, { nullable: true }),
    __metadata("design:type", arc_1.ArcDto)
], NotificationDto.prototype, "arc", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], NotificationDto.prototype, "arcId", void 0);
__decorate([
    (0, graphql_1.Field)(t => arc_1.ArcClaimDto, { nullable: true }),
    __metadata("design:type", arc_1.ArcClaimDto)
], NotificationDto.prototype, "arcClaim", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], NotificationDto.prototype, "arcClaimId", void 0);
__decorate([
    (0, graphql_1.Field)(t => arc_1.ArcReviewDto, { nullable: true }),
    __metadata("design:type", arc_1.ArcReviewDto)
], NotificationDto.prototype, "arcReview", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], NotificationDto.prototype, "arcReviewId", void 0);
__decorate([
    (0, graphql_1.Field)(t => arc_1.ArcReviewSiteDto, { nullable: true }),
    __metadata("design:type", arc_1.ArcReviewSiteDto)
], NotificationDto.prototype, "arcReviewSite", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], NotificationDto.prototype, "arcReviewSiteId", void 0);
NotificationDto = __decorate([
    (0, graphql_2.ObjectType)()
], NotificationDto);
exports.NotificationDto = NotificationDto;
