/**
 * Common helpers
 * DO NOT IMPORT ANY TYPES ETC FROM ANYWHERE ELSE IN COMMON
 * This will result in many typing errors as this is just a standalone helper file.
 * @param str
 */

const window = require('global/window')

export const validStr = (str?: string) => {
  return str !== null && str !== void 0 && str !== ''
}

export const validInt = (num?: any) => {
  if (num == null || num == void 0) return false

  let numb = num
  try {
    numb = parseInt(num.toString())
  } catch {
    return false
  }

  return !isNaN(parseInt(numb.toString()))
}

export const validDate = (date: Date | null | undefined): boolean => {
  return !!date && date !== new Date(0)
}

export const isValidLink = (url: string | undefined): boolean => {
  if (!url) {
    return false
  }
  const regex = new RegExp(/^(http|https):\/\/[^ "]+$/gi)
  return regex.test(url)
}

export const dateMinusDays = (days: number, date: Date = newDate()): Date => {
  const useDate = new Date(date)
  useDate.setDate(date.getDate() - days)
  return useDate
}

export const dateAddDays = (days: number, date: Date = newDate()): Date => {
  const useDate = new Date(date)
  useDate.setDate(date.getDate() + days)
  return useDate
}

export const dateAddMonths = (months: number, date: Date = newDate()): Date => {
  const d = new Date(date) // so we don't mutate the existing date
  return new Date(d.setMonth(d.getMonth() + months))
}

export const daysBetweenDates = (from: Date, to: Date): number => {
  const diffInMilliseconds = from.getTime() - to.getTime()
  const days = diffInMilliseconds / (1000 * 60 * 60 * 24)

  return Math.round(days)
}

export const overDueDaysAllowedSinceLaunch = (isOnNewPlan: boolean) => {
  if (isOnNewPlan) {
    return 20
  }

  // const launchDate = new Date(2022, 2, 7)
  // const daysSinceLaunch = new Date() < launchDate ? 0 : daysBetweenDates(launchDate, new Date())

  // return Math.max(Math.floor((50000 * Math.cos(daysSinceLaunch / 40)) + 50000), 150)
  return 150
}

/**
 * Get the date / time in the timezone we're using throughout the system
 * @param value
 */
export const newDate = (value?: number | string | Date): Date => {
  if (value && validInt(value)) {
    if (value.toString().length > 10) {
      value = Math.floor((value as number) / 1000)
    }
  }

  const d = value ? new Date(value) : new Date()
  return d
}

/**
 * Do a straight up conversion of the date passed in it forces the browser to think it's our local time
 * @param date
 */
export const translateDateToCurrentTimezone = (date: string): Date => {
  const hoursDiff = new Date(date).getTimezoneOffset() / 60
  return new Date(Date.parse(new Date(date).toUTCString()) + (hoursDiff * 60 * 60 * 1000))
}

export const dateMinusTime = (date: Date | undefined) => {
  if (!date) {
    return void 0
  }

  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
}

export const endOfDay = (date: Date): Date => {
  return new Date(date.setUTCHours(23, 59, 59, 999))
}

export const startOfDay = (date: Date): Date => {
  return new Date(date.setUTCHours(0, 0, 0, 0))
}

export const updateUrlHash = (value: any, baseUrl?: string, separator = '#') => {
  if (typeof value === 'object') {
    let str = '?'
    const props = []
    for (const prop of Object.keys(value)) {
      if (value[prop] !== void 0) {
        props.push(`${prop}=${value[prop]}`)
      }
    }
    str += props.join('&')
    updateUrlHash(str, void 0, separator)
  } else {
    if (history.pushState) {
      const url = baseUrl || window.location.href
      const index = url.indexOf(separator)
      const newUrl = index === -1 ? url : url.substr(0, index)
      history.pushState(null, '', newUrl + `${separator}${value}`)
    }
  }
}

export const getUrlHash = (separator = '#'): any => {
  if (!window.location) { // SSR
    return void 0
  }

  const index = window.location.href.indexOf(separator)
  if (index > -1) {
    const hashIndex = separator !== '#' ? index : index + 1
    const hashData = window.location.href.substring(hashIndex)
    // Not an object
    if (!hashData.startsWith('?')) {
      return hashData === 'null' ? '' : hashData
    } else {
      const allObjectParts = hashData.substring(1).split('&')
      const returnObj: any = {}
      for (const part of allObjectParts) {
        const split = part.split('=')
        returnObj[split[0]] = split[1] === 'null' ? '' : decodeURIComponent(split[1])
      }
      return returnObj
    }
  }

  return void 0
}

export const roundWithPrecision = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export const locationOrigin = () => {
  let origin: string

  switch (process.env.BUILD_MODE) {
    case 'alpha': origin = 'https://alpha.booksprout.co'; break
    case 'beta': origin = 'https://beta.booksprout.co'; break
    case 'sandbox': origin = 'https://sandbox.booksprout.co'; break
    case 'master': origin = 'https://booksprout.co'; break
    default: origin = 'https://16e3-82-8-149-134.ngrok.io'; break // Change to whatever ngrok URL we have for dev testing
  }

  return origin
}

export const toCamelCase = (str: string) => {
  return str.replace(/-./g, x=>x[1].toUpperCase())
}

export const ellipsis = (text: string, length: number, ellipsisStr: string = '...', cb: any = void 0) => {
  if (!text) return ''

  if (typeof cb === 'function') {
    cb()
  }

  return text.length > length - ellipsisStr.length
    ? text.substring(0, length - ellipsisStr.length) + ellipsisStr
    : text
}

/**
 * Go through an object recursively and replace any props \\ with / for the URL.
 * @param obj
 */
export const normaliseWebPathsRecursive = (obj: any) => {
  if (!obj) {
    return obj
  }

  for (const key of Object.keys(obj)) {
    if (typeof obj[key] === 'object') {
      normaliseWebPathsRecursive(obj[key])
    } else {
      if (['bookCover', 'image', 'pdfFile', 'epubFile', 'avatar'].includes(key)) {
        obj[key] = obj[key].replace(/\\/g, '/')
      }
    }
  }
}

export const replaceUrlParam = (url: string, paramName: string, paramValue: string) => {
  if (paramValue == null) {
    paramValue = ''
  }
  const pattern = new RegExp('\\b('+paramName+'=).*?(&|#|$)')
  if (url.search(pattern)>=0) {
    return url.replace(pattern,'$1' + paramValue + '$2')
  }
  url = url.replace(/[?#]$/,'')
  return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue
}
