"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserSeeder = void 0;
const seeder_1 = require("../seeder");
const module_constants_1 = require("../../module.constants");
const user_1 = require("../../modules/user");
class UserSeeder extends seeder_1.Seeder {
    constructor() {
        super(...arguments);
        this.type = 'user';
        this.maxModelCount = 80;
        this.base = {
            // password: '$2b$13$gE.T8ENiwgqRyP/qlL26MO6w4ocvp4lEK87LDksqHRufiUR5e39vK', // 123
            password: '123',
            reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW,
            authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.GROWING,
            consentMethod: module_constants_1.bsConstants.USER.CONSENT_METHOD.SIGN_UP_PAGE,
            image: 'FakeUser.png',
            preferences: Object.assign(Object.assign({}, user_1.defaultPreferences), { reviewSitesFlag: module_constants_1.bsConstants.ARCS.REVIEWS.ALL_FLAGS }),
            confirmEmail: true
        };
    }
    getFakeSeed(id) {
        return Object.assign(Object.assign({ id }, this.base), { emailAddress: this.faker.internet.email(), name: this.faker.name.firstName() + ' ' + this.faker.name.lastName() });
    }
    getLiveDataObject() {
        return {
            BooksproutSupport: Object.assign(Object.assign({ id: 1 }, this.base), { emailAddress: 'support@booksprout.co', name: 'Booksprout Support', image: 'booksprout-admin.svg', isAdmin: true, password: '$2b$13$uDYxj2boi7AmImwIlc.ySOuJ0hGbBI7SAd26PRWds.W4CKzfW9nIW' }),
            DeletedUser: Object.assign(Object.assign({ id: 2 }, this.base), { emailAddress: 'N/A', name: '[Deleted User]', image: 'booksprout-admin.svg', password: '-1' })
        };
    }
    getTestDataObject() {
        if (process.env.BUILD_MODE === 'master') {
            return {};
        }
        const testData = {
            Admin: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'admin@allangaunt.dev', name: 'Booksprout Admin User' }),
            Me: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'me@allangaunt.dev', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.PUBLISHER, name: 'Allan Gaunt', image: 'AllanGaunt.png', pirateId: 'sameUserPirateId' }),
            Va: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'va@allangaunt.dev', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW, authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.PRO, name: 'Miss Virtual Assistant', image: 'VA.png' }),
            OldBsUser: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'allangaunt@gmail.com', password: '$2y$13$GS0JW0Rg5dkuPV2ji9ULSOnN9sP2NN4YxQBWCu2B62XPwI0bGzfMS', authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.PUBLISHER, name: 'Allan Gaunt Old', image: 'AllanGauntOld.jpg' }),
            StanRizzo: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'stan.rizzo@allangaunt.dev', authorRole: void 0, name: 'Stan Rizzo' }),
            DarthVadar: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'darth.vadar@allangaunt.dev', authorRole: void 0, name: 'Darth Vadar', image: 'FakeUser.png' }),
            LukeSkywalker: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'luke.skywalker@allangaunt.dev', authorRole: void 0, name: 'Luke Skywalker' }),
            MaraJade: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'mara.jade@allangaunt.dev', authorRole: void 0, name: 'Mara Jade' }),
            HanSolo: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'han.solo@allangaunt.dev', authorRole: void 0, name: 'Han Solo', pirateId: 'validPirateId', preferences: Object.assign(Object.assign({}, user_1.defaultPreferences), { reviewSitesFlag: module_constants_1.bsConstants.ARCS.REVIEWS.ALL_FLAGS }) }),
            Thrawn: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'thrawn@allangaunt.dev', authorRole: void 0, name: 'Admiral Thrawn' }),
            Publisher: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'publisher@allangaunt.dev', authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.PUBLISHER, name: 'I Am Publisher', image: 'Publisher.jpg' }),
            FreeAuthor: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'free.author@allangaunt.dev', authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.FREE, name: 'Free Author' }),
            GrowingAuthor: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'growing.author@allangaunt.dev', authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.GROWING, name: 'Growing Author' }),
            ProAuthor: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'pro.author@allangaunt.dev', authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.PRO, name: 'Pro Author' }),
            BestsellingAuthor: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'bestselling.author@allangaunt.dev', authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.BEST_SELLING, name: 'Bestselling Author' }),
            NonVerifiedAuthor: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'non.verified@allangaunt.dev', authorRole: void 0, name: 'Non Verified Author', confirmEmail: false }),
            HasReviewDueSoon: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'has.review.due.soon@allangaunt.dev', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, authorRole: void 0, name: 'Review Due Soon User' }),
            ApproveUser: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'approve.test@allangaunt.dev', authorRole: void 0, name: 'Approve this user' }),
            RejectUser: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'reject.test@allangaunt.dev', authorRole: void 0, name: 'Reject this user' }),
            ReviewerNoAuthor: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'reviewer.no.author@allangaunt.dev', name: 'Reviewer Only', authorRole: void 0 }),
            YourArcs: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'your.arcs@allangaunt.dev', name: 'Your ARCS', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, lockRoles: true }),
            ClaimNonMemberArcDownloadsLocked: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'downloads.locked@allangaunt.dev', name: 'Downloads Locked', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, statuses: [
                    {
                        userId: 1,
                        type: module_constants_1.bsConstants.USER.USER_STATUS.PREVENT_ARC_DOWNLOADS,
                        active: true,
                        isRestriction: true,
                        additionalInformation: 'You\'ve been a very naughty boy!'
                    },
                    {
                        userId: 1,
                        type: module_constants_1.bsConstants.USER.USER_STATUS.SHADOW_BAN,
                        active: false,
                        isRestriction: true,
                        additionalInformation: 'Naughty boy'
                    },
                    {
                        userId: 1,
                        type: module_constants_1.bsConstants.USER.USER_STATUS.LOCKED,
                        active: false,
                        isRestriction: true,
                        additionalInformation: 'Really naughty boy'
                    }
                ] }),
            ClaimNonMemberTooManyDue: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'too.many.due@allangaunt.dev', name: 'TooMany Reviews', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW, lockRoles: true }),
            ClaimNonMemberDueTooLong: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'due.too.long@allangaunt.dev', name: 'DueToo Long', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, lockRoles: true }),
            ClaimNonMemberWeeklyLimitReached: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'nm.weekly.limit@allangaunt.dev', name: 'NonMemberClaim WeeklyLimitReached', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW, lockRoles: true }),
            ClaimNonMemberWeeklyLimitReachedValued: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'nm.weekly.limit.valued@allangaunt.dev', name: 'NonMemberClaim WeeklyLimitReached Valued Reviewer', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, lockRoles: true }),
            ClaimOutstandingTeamReviewNotVIP: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'has.review.for.team@allangaunt.dev', name: 'HasOutstanding TeamReview', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, lockRoles: true }),
            ClaimBlockedUser: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'blocked.user@allangaunt.dev', name: 'Blocked User', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, lockRoles: true }),
            UserAlreadyClaimedBookBefore: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'claimed.book.before@allangaunt.dev', name: 'UserClaimed BookBefore', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, lockRoles: true }),
            ClaimUnverifiedEmail: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'unverified@allangaunt.dev', name: 'Unverified Email', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, lockRoles: true, confirmEmail: false }),
            ClaimNewReviewerNoTeam: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'no.team.new.reviewer@allangaunt.dev', name: 'New reviewer no team', authorRole: void 0 }),
            LimitedStores: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'limited.stores@allangaunt.dev', name: 'Limited stores', authorRole: void 0, preferences: Object.assign(Object.assign({}, user_1.defaultPreferences), { reviewSitesFlag: module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.KOBO | module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.GOOGLE }) }),
            BadBoy: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'bad.boy@allangaunt.dev', name: '_Bad Boy', authorRole: void 0 }),
            NotificationAuthor: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'notif.a@allangaunt.dev', name: 'Author Notification', authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.BEST_SELLING }),
            NotificationReviewer: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'notif.r@allangaunt.dev', name: 'Reviewer Notification', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED }),
            AppliedForTopReviewer: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'applied.for.tr@allangaunt.dev', name: 'Top Reviewer Applicant', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, appliedForTopReviewer: true }),
            Pirate: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'pirate@allangaunt.dev', name: 'Pirate', pirateId: 'pirateUser', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW }),
            ShadowBan: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'shadow.ban@allangaunt.dev', name: 'Shadow banned user', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW, statuses: [
                    {
                        userId: 1,
                        type: module_constants_1.bsConstants.USER.USER_STATUS.SHADOW_BAN,
                        active: true,
                        isRestriction: true,
                        additionalInformation: 'Free ban from all of us at Booksprout. Enjoy!'
                    }
                ] }),
            NoName: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'no.name@allangaunt.dev', name: '', authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.FREE, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW }),
            ReviewerNoName: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'reviewer.no.name@allangaunt.dev', name: '', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW }),
            ReviewerNoStores: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'reviewer.no.stores@allangaunt.dev', name: 'Reviewer - no stores', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW, preferences: Object.assign(Object.assign({}, user_1.defaultPreferences), { reviewSitesFlag: module_constants_1.bsConstants.ARCS.REVIEWS.FLAGS.NONE }) }),
            Locked: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'locked@allangaunt.dev', name: 'Locked user', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW, statuses: [
                    {
                        userId: 1,
                        type: module_constants_1.bsConstants.USER.USER_STATUS.LOCKED,
                        active: true,
                        isRestriction: true,
                        additionalInformation: 'Locked with no ability to log in'
                    }
                ] }),
            RegistrationReviewer: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'registration.reviewer@allangaunt.dev', name: 'Registration Reviewer', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.UNSET, authorRole: void 0 }),
            RegistrationAuthor: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'registration.author@allangaunt.dev', name: 'Registration Author', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.UNSET, authorRole: void 0 }),
            RegistrationPublisher: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'registration.publisher@allangaunt.dev', name: 'Registration Publisher', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.UNSET, authorRole: void 0 }),
            RegistrationVaAuthor: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'registration.va.author@allangaunt.dev', name: 'Registration VA Author', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.UNSET, authorRole: void 0 }),
            RegistrationVaReviewer: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'registration.va.reviewer@allangaunt.dev', name: 'Registration VA Reviewer', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.UNSET, authorRole: void 0 }),
            NewAuthor: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'new.author@allangaunt.dev', name: 'New Author', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW, authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.FREE }),
            ComplexOverdue: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'complex.overdue@allangaunt.dev', name: 'Reviewer for complex rules', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.TOP, authorRole: void 0 }),
            ReviewerNotClaimedBefore: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'reviewer.not.claimed.before@allangaunt.dev', name: 'Reviewer not claimed before', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, authorRole: void 0 }),
            ClaimNonMemberOutstandingReviewForThisTeam: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'claim.non.member.outstanding.review.for.this.team@allangaunt.dev', name: 'Claim non member outstanding review for this team', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, authorRole: void 0 }),
            AutoApproveOnTeam: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'auto.approve.on.team@allangaunt.dev', name: 'Auto approve on team', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW, authorRole: void 0 }),
            AutoBlockOnTeam: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'auto.block.on.team@allangaunt.dev', name: 'Auto block on team', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW, authorRole: void 0 }),
            NotificationTopReviewer: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'notification.top.reviewer@allangaunt.dev', name: 'Notification top reviewer', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.TOP, authorRole: void 0 }),
            TopReviewerApplicationRejected: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'tr.application.rejected@allangaunt.dev', name: 'Top Reviewer Application Rejected', authorRole: void 0, reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.VALUED, rejectedTopReviewer: true }),
            UserForRandomArcs: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'user.for.random.arcs@allangaunt.dev', name: 'User for random arcs', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW, authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.PUBLISHER }),
            BlockNotTeamMember: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'block.not.team.member@allangaunt.dev', name: 'Block not team member', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW, authorRole: void 0 }),
            EbookAndAudioBook: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'ebookandaudiobook@allangaunt.dev', name: 'User hosting ebook and audio book at same time', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW, authorRole: module_constants_1.bsConstants.USER.ROLES.AUTHOR.PUBLISHER }),
            EbookAndAudioBookReviewer: Object.assign(Object.assign({ id: this.getNextId() }, this.base), { emailAddress: 'ebookandaudiobook.rev@allangaunt.dev', name: 'User reviewing ebook and audio book at same time', reviewerRole: module_constants_1.bsConstants.USER.ROLES.REVIEWER.NEW, authorRole: void 0 })
        };
        return Object.assign(Object.assign({}, testData), { RandomUsers: this.generateRandomData() });
    }
}
exports.UserSeeder = UserSeeder;
