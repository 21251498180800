"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateBookDto = void 0;
const graphql_1 = require("@nestjs/graphql");
const baseUpdate_dto_1 = require("../base/baseUpdate.dto");
let UpdateBookDto = class UpdateBookDto extends baseUpdate_dto_1.BaseUpdateDto {
    constructor() {
        super(...arguments);
        this.penNameType = 0;
    }
};
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "title", void 0);
__decorate([
    (0, graphql_1.Field)(t => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "description", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "penName", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateBookDto.prototype, "authorPenNameId", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { defaultValue: 0, nullable: true }),
    __metadata("design:type", Number)
], UpdateBookDto.prototype, "penNameType", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "bookCover", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], UpdateBookDto.prototype, "releaseDate", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "seriesName", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateBookDto.prototype, "seriesNumber", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateBookDto.prototype, "categoryId", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "keywords", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateBookDto.prototype, "wordCount", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateBookDto.prototype, "audience", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkAmazonAsin", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkAmazonAsinPrint", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkGoodreads", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkBookbub", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkBarnes", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkGoogle", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkKobo", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkSmashwords", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "smashwordsCouponCode", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkITunes", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkAudibleUs", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkAudibleUk", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkITunesAudio", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkBarnesAudio", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkGoogleAudio", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkGoodreadsAudio", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkKoboAudio", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "linkBookbubAudio", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateBookDto.prototype, "source", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "pdfFile", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateBookDto.prototype, "pdfFileSizeBytes", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], UpdateBookDto.prototype, "pdfSystemGenerated", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], UpdateBookDto.prototype, "pdfFileDate", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "epubFile", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateBookDto.prototype, "epubFileSizeBytes", void 0);
__decorate([
    (0, graphql_1.Field)(type => String, { nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "epubPreviewFile", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], UpdateBookDto.prototype, "epubSystemGenerated", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], UpdateBookDto.prototype, "epubFileDate", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], UpdateBookDto.prototype, "generatedNewEpub", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "epubFileFriendlyName", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UpdateBookDto.prototype, "pdfFileFriendlyName", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], UpdateBookDto.prototype, "awaitingBookFileFlag", void 0);
UpdateBookDto = __decorate([
    (0, graphql_1.ArgsType)()
], UpdateBookDto);
exports.UpdateBookDto = UpdateBookDto;
