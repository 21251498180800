"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Seeder = void 0;
const faker = require('faker');
class Seeder {
    constructor() {
        this.nextId = 1;
        // getNextId will only be used in test data so miss the id numbers from live (which starts at 1).
        const liveData = this.getLiveSeed() || [];
        this.nextId = liveData.length + 1;
        this.faker = {
            name: {
                firstName: () => '',
                lastName: () => ''
            },
            internet: {
                email: () => ''
            },
            lorem: {
                paragraph: () => ''
            }
        };
    }
    /**
     * Can be overridden on a derived type to provide a faked model for test data
     * @param id
     */
    getFakeSeed(id) {
        return void 0;
    }
    /**
     * Flat array of getLiveDataObject without the keys
     */
    getLiveSeed() {
        return this.flattenSeedDataObject(this.getLiveDataObject());
    }
    /**
     * Flat array of getLiveDataObject without the keys
     */
    getTestSeed() {
        this.faker = faker;
        return this.flattenSeedDataObject(this.getTestDataObject());
    }
    /**
     * NOTE: For testing data ONLY! Uses live seed as an offset
     */
    getNextId() {
        return this.nextId++;
    }
    generateRandomData() {
        const random = [];
        let nextId = this.getNextId();
        while (nextId <= this.maxModelCount) {
            const model = this.getFakeSeed(nextId);
            if (model !== void 0) {
                random.push(model);
                nextId = this.getNextId();
            }
        }
        return random;
    }
    getSeedWithId(seedData, id) {
        for (const key in seedData) {
            if (seedData.hasOwnProperty(key)) {
                const model = seedData[key];
                if (model.id === id) {
                    return model;
                }
            }
        }
        return void 0;
    }
    /**
     * Take an object like { someKey: SomeSeedModel, someOtherKey: SomeSeedModel } and return SomeSeedModel[]
     * @param data
     */
    flattenSeedDataObject(data) {
        if (!data)
            return [];
        let result = [];
        for (const key in data) {
            const model = data[key];
            if (Array.isArray(model)) {
                result = result.concat(model);
            }
            else {
                result.push(model);
            }
        }
        return result;
    }
}
exports.Seeder = Seeder;
