"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserStatusDto = void 0;
const graphql_1 = require("@nestjs/graphql");
const base_1 = require("../../base");
const basicModel_dto_1 = require("../basicModel.dto");
let UserStatusDto = class UserStatusDto extends base_1.BaseModelDto {
};
__decorate([
    (0, graphql_1.Field)(t => basicModel_dto_1.BasicUserDto, { nullable: true }),
    __metadata("design:type", basicModel_dto_1.BasicUserDto)
], UserStatusDto.prototype, "adminUser", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], UserStatusDto.prototype, "adminUserId", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], UserStatusDto.prototype, "type", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Boolean)
], UserStatusDto.prototype, "active", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Boolean)
], UserStatusDto.prototype, "isRestriction", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], UserStatusDto.prototype, "additionalInformation", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], UserStatusDto.prototype, "expiryDate", void 0);
UserStatusDto = __decorate([
    (0, graphql_1.ObjectType)()
], UserStatusDto);
exports.UserStatusDto = UserStatusDto;
