"use strict";
/**
 * Common helpers
 * DO NOT IMPORT ANY TYPES ETC FROM ANYWHERE ELSE IN COMMON
 * This will result in many typing errors as this is just a standalone helper file.
 * @param str
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceUrlParam = exports.normaliseWebPathsRecursive = exports.ellipsis = exports.toCamelCase = exports.locationOrigin = exports.roundWithPrecision = exports.getUrlHash = exports.updateUrlHash = exports.startOfDay = exports.endOfDay = exports.dateMinusTime = exports.translateDateToCurrentTimezone = exports.newDate = exports.overDueDaysAllowedSinceLaunch = exports.daysBetweenDates = exports.dateAddMonths = exports.dateAddDays = exports.dateMinusDays = exports.isValidLink = exports.validDate = exports.validInt = exports.validStr = void 0;
const window = require('global/window');
const validStr = (str) => {
    return str !== null && str !== void 0 && str !== '';
};
exports.validStr = validStr;
const validInt = (num) => {
    if (num == null || num == void 0)
        return false;
    let numb = num;
    try {
        numb = parseInt(num.toString());
    }
    catch (_a) {
        return false;
    }
    return !isNaN(parseInt(numb.toString()));
};
exports.validInt = validInt;
const validDate = (date) => {
    return !!date && date !== new Date(0);
};
exports.validDate = validDate;
const isValidLink = (url) => {
    if (!url) {
        return false;
    }
    const regex = new RegExp(/^(http|https):\/\/[^ "]+$/gi);
    return regex.test(url);
};
exports.isValidLink = isValidLink;
const dateMinusDays = (days, date = (0, exports.newDate)()) => {
    const useDate = new Date(date);
    useDate.setDate(date.getDate() - days);
    return useDate;
};
exports.dateMinusDays = dateMinusDays;
const dateAddDays = (days, date = (0, exports.newDate)()) => {
    const useDate = new Date(date);
    useDate.setDate(date.getDate() + days);
    return useDate;
};
exports.dateAddDays = dateAddDays;
const dateAddMonths = (months, date = (0, exports.newDate)()) => {
    const d = new Date(date); // so we don't mutate the existing date
    return new Date(d.setMonth(d.getMonth() + months));
};
exports.dateAddMonths = dateAddMonths;
const daysBetweenDates = (from, to) => {
    const diffInMilliseconds = from.getTime() - to.getTime();
    const days = diffInMilliseconds / (1000 * 60 * 60 * 24);
    return Math.round(days);
};
exports.daysBetweenDates = daysBetweenDates;
const overDueDaysAllowedSinceLaunch = (isOnNewPlan) => {
    if (isOnNewPlan) {
        return 20;
    }
    // const launchDate = new Date(2022, 2, 7)
    // const daysSinceLaunch = new Date() < launchDate ? 0 : daysBetweenDates(launchDate, new Date())
    // return Math.max(Math.floor((50000 * Math.cos(daysSinceLaunch / 40)) + 50000), 150)
    return 150;
};
exports.overDueDaysAllowedSinceLaunch = overDueDaysAllowedSinceLaunch;
/**
 * Get the date / time in the timezone we're using throughout the system
 * @param value
 */
const newDate = (value) => {
    if (value && (0, exports.validInt)(value)) {
        if (value.toString().length > 10) {
            value = Math.floor(value / 1000);
        }
    }
    const d = value ? new Date(value) : new Date();
    return d;
};
exports.newDate = newDate;
/**
 * Do a straight up conversion of the date passed in it forces the browser to think it's our local time
 * @param date
 */
const translateDateToCurrentTimezone = (date) => {
    const hoursDiff = new Date(date).getTimezoneOffset() / 60;
    return new Date(Date.parse(new Date(date).toUTCString()) + (hoursDiff * 60 * 60 * 1000));
};
exports.translateDateToCurrentTimezone = translateDateToCurrentTimezone;
const dateMinusTime = (date) => {
    if (!date) {
        return void 0;
    }
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
};
exports.dateMinusTime = dateMinusTime;
const endOfDay = (date) => {
    return new Date(date.setUTCHours(23, 59, 59, 999));
};
exports.endOfDay = endOfDay;
const startOfDay = (date) => {
    return new Date(date.setUTCHours(0, 0, 0, 0));
};
exports.startOfDay = startOfDay;
const updateUrlHash = (value, baseUrl, separator = '#') => {
    if (typeof value === 'object') {
        let str = '?';
        const props = [];
        for (const prop of Object.keys(value)) {
            if (value[prop] !== void 0) {
                props.push(`${prop}=${value[prop]}`);
            }
        }
        str += props.join('&');
        (0, exports.updateUrlHash)(str, void 0, separator);
    }
    else {
        if (history.pushState) {
            const url = baseUrl || window.location.href;
            const index = url.indexOf(separator);
            const newUrl = index === -1 ? url : url.substr(0, index);
            history.pushState(null, '', newUrl + `${separator}${value}`);
        }
    }
};
exports.updateUrlHash = updateUrlHash;
const getUrlHash = (separator = '#') => {
    if (!window.location) { // SSR
        return void 0;
    }
    const index = window.location.href.indexOf(separator);
    if (index > -1) {
        const hashIndex = separator !== '#' ? index : index + 1;
        const hashData = window.location.href.substring(hashIndex);
        // Not an object
        if (!hashData.startsWith('?')) {
            return hashData === 'null' ? '' : hashData;
        }
        else {
            const allObjectParts = hashData.substring(1).split('&');
            const returnObj = {};
            for (const part of allObjectParts) {
                const split = part.split('=');
                returnObj[split[0]] = split[1] === 'null' ? '' : decodeURIComponent(split[1]);
            }
            return returnObj;
        }
    }
    return void 0;
};
exports.getUrlHash = getUrlHash;
const roundWithPrecision = (value, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
};
exports.roundWithPrecision = roundWithPrecision;
const locationOrigin = () => {
    let origin;
    switch (process.env.BUILD_MODE) {
        case 'alpha':
            origin = 'https://alpha.booksprout.co';
            break;
        case 'beta':
            origin = 'https://beta.booksprout.co';
            break;
        case 'sandbox':
            origin = 'https://sandbox.booksprout.co';
            break;
        case 'master':
            origin = 'https://booksprout.co';
            break;
        default:
            origin = 'https://16e3-82-8-149-134.ngrok.io';
            break; // Change to whatever ngrok URL we have for dev testing
    }
    return origin;
};
exports.locationOrigin = locationOrigin;
const toCamelCase = (str) => {
    return str.replace(/-./g, x => x[1].toUpperCase());
};
exports.toCamelCase = toCamelCase;
const ellipsis = (text, length, ellipsisStr = '...', cb = void 0) => {
    if (!text)
        return '';
    if (typeof cb === 'function') {
        cb();
    }
    return text.length > length - ellipsisStr.length
        ? text.substring(0, length - ellipsisStr.length) + ellipsisStr
        : text;
};
exports.ellipsis = ellipsis;
/**
 * Go through an object recursively and replace any props \\ with / for the URL.
 * @param obj
 */
const normaliseWebPathsRecursive = (obj) => {
    if (!obj) {
        return obj;
    }
    for (const key of Object.keys(obj)) {
        if (typeof obj[key] === 'object') {
            (0, exports.normaliseWebPathsRecursive)(obj[key]);
        }
        else {
            if (['bookCover', 'image', 'pdfFile', 'epubFile', 'avatar'].includes(key)) {
                obj[key] = obj[key].replace(/\\/g, '/');
            }
        }
    }
};
exports.normaliseWebPathsRecursive = normaliseWebPathsRecursive;
const replaceUrlParam = (url, paramName, paramValue) => {
    if (paramValue == null) {
        paramValue = '';
    }
    const pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
    if (url.search(pattern) >= 0) {
        return url.replace(pattern, '$1' + paramValue + '$2');
    }
    url = url.replace(/[?#]$/, '');
    return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
};
exports.replaceUrlParam = replaceUrlParam;
