"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AuthorRole_1 = require("./AuthorRole");
class GrowingAuthor extends AuthorRole_1.AuthorRole {
    constructor() {
        super(...arguments);
        this.maxParticipantsPerArc = 25;
        this.maxRunningArcs = 1;
    }
}
exports.default = GrowingAuthor;
