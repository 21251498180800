"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArcReviewSiteDto = void 0;
const graphql_1 = require("@nestjs/graphql");
const model_dto_1 = require("./model.dto");
const baseModel_dto_1 = require("../../base/baseModel.dto");
let ArcReviewSiteDto = class ArcReviewSiteDto extends baseModel_dto_1.BaseModelDto {
};
__decorate([
    (0, graphql_1.Field)(type => model_dto_1.ArcReviewDto, { nullable: true }),
    __metadata("design:type", model_dto_1.ArcReviewDto)
], ArcReviewSiteDto.prototype, "review", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], ArcReviewSiteDto.prototype, "reviewId", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], ArcReviewSiteDto.prototype, "status", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Date)
], ArcReviewSiteDto.prototype, "statusDate", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Number)
], ArcReviewSiteDto.prototype, "state", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], ArcReviewSiteDto.prototype, "site", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcReviewSiteDto.prototype, "link", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcReviewSiteDto.prototype, "statusReason", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Boolean)
], ArcReviewSiteDto.prototype, "exempt", void 0);
ArcReviewSiteDto = __decorate([
    (0, graphql_1.ObjectType)()
], ArcReviewSiteDto);
exports.ArcReviewSiteDto = ArcReviewSiteDto;
