import { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'

// Mixins
import CommonMixin from '../mixins/common'

@Component
export default class BsFormField extends CommonMixin {
  @Prop({ type: Boolean }) readonly wrap!: boolean
  @Prop({ type: Boolean }) readonly dense!: boolean
  @Prop({ type: Boolean }) readonly multiLineHint!: boolean

  __renderTitleSection (h: CreateElement) {
    if (!this.$slots.title && !this.$slots.hint) {
      return void 0
    }

    return h('div', {
      staticClass: 'bs-form-field__container'
    }, [
      this.$slots.title && h('div', {
        staticClass: 'bs-form-field__title',
        attrs: {
          'aria-hidden': true
        }
      }, this.slot(this, 'title')),
      this.$slots.hint && h('p', {
        staticClass: 'bs-form-field__hint',
        class: this.multiLineHint ? 'bs-form-field__hint--multiline' : void 0
      }, this.slot(this, 'hint'))
    ])
  }

  render (h: CreateElement) {
    return h('div', {
      staticClass: 'bs-form-field',
      class: {
        'bs-form-field--wrap': this.wrap,
        'bs-form-field--dense': this.dense
      },
      on: this.getListeners()
    }, [
      this.__renderTitleSection(h),
      h('div', {
        staticClass: 'bs-form-field__content'
      }, this.slot(this, 'default'))
    ])
  }
}
