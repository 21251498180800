"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorRole = exports.baseAuthorCRUDModels = void 0;
exports.baseAuthorCRUDModels = ['pen-name', 'book', 'arc-team', 'arc', 'review', 'category', 'message'];
class AuthorRole {
    constructor() {
        // CRUD operation permissions
        this.create = exports.baseAuthorCRUDModels;
        this.read = exports.baseAuthorCRUDModels.concat(['billing']);
        this.update = exports.baseAuthorCRUDModels;
        this.delete = exports.baseAuthorCRUDModels;
        // Generic Boolean Permissions
        this.maxParticipantsPerArc = 0;
        this.maxRunningArcs = 0;
        this.canCreatePrivateArcs = false;
        this.canHaveVIPTeamMembers = false;
        this.canCreateAudioBookArcs = false;
        this.canScheduleArcs = false;
        this.canLimitReviewersToAllOnSeries = false;
        this.canIdentifyPiracy = false;
        this.canRequireReviewOnPreviousBooksInSeries = false;
        this.canShowSocialLinksOnAuthorPages = false;
        this.canLimitReviewersToTopReviewersOnly = false;
        this.canAccessPremiumSupport = false;
        this.canUseAdvancedReviewIssueDetection = false;
        this.canAddVirtualAssistants = false;
        this.canViewExtendedReviewerInformation = false;
        this.canHaveNeverEndingArcs = false;
        this.canChooseDefaultReviewDisclaimer = false;
        this.canFreeTypePenNames = false;
        this.canChooseBrandedDefaultReviewDisclaimer = false;
        this.canHaveTeamFollowers = false;
    }
}
exports.AuthorRole = AuthorRole;
