"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArcClaimDto = void 0;
const graphql_1 = require("@nestjs/graphql");
const booksprout_1 = require("booksprout");
const baseModel_dto_1 = require("../../base/baseModel.dto");
const review_1 = require("../review");
let ArcClaimDto = class ArcClaimDto extends baseModel_dto_1.BaseModelDto {
    constructor() {
        super(...arguments);
        this.reviewDays = 0;
        this.vipExclusiveAccessDays = 0;
    }
};
__decorate([
    (0, graphql_1.Field)(type => booksprout_1.ArcDto, { nullable: true }),
    __metadata("design:type", booksprout_1.ArcDto)
], ArcClaimDto.prototype, "arc", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], ArcClaimDto.prototype, "status", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Number)
], ArcClaimDto.prototype, "state", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Date)
], ArcClaimDto.prototype, "statusDate", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimDto.prototype, "cancellationType", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcClaimDto.prototype, "cancellationReason", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { defaultValue: 0 }),
    __metadata("design:type", Number)
], ArcClaimDto.prototype, "reviewFlags", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { defaultValue: 0, nullable: true }),
    __metadata("design:type", Number)
], ArcClaimDto.prototype, "optionalReviewFlags", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimDto.prototype, "requiredReviewCount", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", Date)
], ArcClaimDto.prototype, "stopDownloadDate", void 0);
__decorate([
    (0, graphql_1.Field)(type => graphql_1.Int, { defaultValue: 0 }),
    __metadata("design:type", Number)
], ArcClaimDto.prototype, "reviewDays", void 0);
__decorate([
    (0, graphql_1.Field)(t => review_1.ArcReviewDto, { nullable: true }),
    __metadata("design:type", review_1.ArcReviewDto)
], ArcClaimDto.prototype, "review", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int),
    __metadata("design:type", Number)
], ArcClaimDto.prototype, "reviewId", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { defaultValue: 0 }),
    __metadata("design:type", Number)
], ArcClaimDto.prototype, "vipExclusiveAccessDays", void 0);
__decorate([
    (0, graphql_1.Field)(),
    __metadata("design:type", Date)
], ArcClaimDto.prototype, "dueDate", void 0);
__decorate([
    (0, graphql_1.Field)(t => booksprout_1.UserDto, { nullable: true }),
    __metadata("design:type", booksprout_1.UserDto)
], ArcClaimDto.prototype, "user", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcClaimDto.prototype, "additionalInformation", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcClaimDto.prototype, "downloadCode", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimDto.prototype, "selectedAudioSiteFlag", void 0);
__decorate([
    (0, graphql_1.Field)(t => Boolean, { nullable: true }),
    __metadata("design:type", Boolean)
], ArcClaimDto.prototype, "authorOwnsArc", void 0);
__decorate([
    (0, graphql_1.Field)({ nullable: true }),
    __metadata("design:type", String)
], ArcClaimDto.prototype, "lastReadCfi", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimDto.prototype, "overdueDays", void 0);
__decorate([
    (0, graphql_1.Field)(t => Boolean, { nullable: true }),
    __metadata("design:type", Boolean)
], ArcClaimDto.prototype, "canFollowTeam", void 0);
__decorate([
    (0, graphql_1.Field)(t => graphql_1.Int, { nullable: true }),
    __metadata("design:type", Number)
], ArcClaimDto.prototype, "reviewerStatus", void 0);
ArcClaimDto = __decorate([
    (0, graphql_1.ObjectType)()
], ArcClaimDto);
exports.ArcClaimDto = ArcClaimDto;
